import React from 'react'

import { Box, Button, Card, Heading, Inline, Modal, Stack, Text } from '@myxplor/stardust'

import CloseIcon from '~/icons/Close'

interface Props {
  visitor: Nullable<Xplor.Visitor>
  onConfirm(visitor: Xplor.Visitor): void
  onCancel(): void
}
const SignOutConfirmation = ({ visitor, onConfirm, onCancel }: Props) => {
  const firstName = visitor ? visitor.first_name : 'Place'
  const lastName = visitor ? visitor.last_name : 'Holder'
  const safeOnConfirm = () => visitor && onConfirm(visitor)

  return (
    <Modal isOpen={!!visitor} onBlur={onCancel}>
      <Card>
        <Box maxWidth="400px" padding="large">
          <Box color="lowEmphasis" padding="small" position="absolute" right={0} top={0} onClick={onCancel}>
            <CloseIcon />
          </Box>

          <Stack space="small">
            <Heading level={6}>Sign out</Heading>

            <Text color="highestEmphasis" prefab="body">
              Are you sure you want to sign out {`${firstName} ${lastName}`}?
            </Text>

            <Box paddingTop="large" width={1}>
              <Inline align="right" space="xsmall">
                <Button variant="outline" onClick={onCancel}>
                  Cancel
                </Button>
                <Button onClick={safeOnConfirm}>Sign out</Button>
              </Inline>
            </Box>
          </Stack>
        </Box>
      </Card>
    </Modal>
  )
}

export default React.memo(SignOutConfirmation)
