import React, { useState } from 'react'

import { Box, Columns, Column, Stack, Text, useColor } from '@myxplor/stardust'

import { useInterval } from '../../hooks/useInterval'

import { getLocalDateTime, formatTime, getTimeInterval, getTimeIntervalPercentage } from '~/helpers/dates'

interface Props {
  shift: Xplor.Shift
  background?: string
}

interface BreakBars {
  startPercentage: number
  endPercentage: number
}

const barHeight = '8px'
const refreshRate = 60000

const ShiftProgressBar = (props: Props) => {
  const startTime = props.shift.start_time ? props.shift.start_time : '12:00:00'
  const finishTime = props.shift.finish_time ? props.shift.finish_time : '23:00:00'
  const shiftLength = getTimeInterval(`${startTime}`, `${finishTime}`)
  const secondaryColor = useColor('secondary')

  const updateShiftBackground = () => {
    const elapsedTime = getTimeInterval(`${startTime}`, getLocalDateTime())
    const elapsedPercentage: number = Math.min(
      Math.floor((elapsedTime.length('minutes') / shiftLength.length('minutes')) * 100),
      100
    )
    return `linear-gradient(to right, ${secondaryColor} ${elapsedPercentage}%, #FFFFFF ${elapsedPercentage}%)`
  }

  const getBreakProgressBars = () => {
    const breakBoxes: BreakBars[] = []
    const breaks = props.shift.attendance.break.sort((a, b) => {
      const intervalA = getTimeInterval(getLocalDateTime(), a.start_time)
      const intervalB = getTimeInterval(getLocalDateTime(), b.start_time)
      return intervalA < intervalB ? 1 : -1
    })

    breaks.forEach(({ start_time, finish_time, status }, index) => {
      const startTime = start_time ? start_time : '00:00:00'
      const finishTime = finish_time ? finish_time : '00:00:00'

      let endTimePercentage = 0
      const startTimePercentage: number = getTimeIntervalPercentage(
        props.shift.start_time,
        startTime,
        shiftLength
      )

      if (status === 'CHECKED_OUT') {
        // Show bar from break start time to break finish time
        endTimePercentage = getTimeIntervalPercentage(props.shift.start_time, finishTime, shiftLength)
      } else {
        // Show bar from break start to current time
        endTimePercentage = getTimeIntervalPercentage(props.shift.start_time, getLocalDateTime(), shiftLength)
      }
      breakBoxes.push({
        startPercentage: startTimePercentage,
        endPercentage: endTimePercentage,
      })
    })
    return breakBoxes
  }

  const [shiftBackground, setShiftBackground] = useState(updateShiftBackground())

  useInterval(() => {
    setShiftBackground(updateShiftBackground())
  }, refreshRate)

  return (
    <Columns alignY="center" space="xxsmall">
      <Column align="right" width="75px">
        <Text prefab="caption">{formatTime(startTime)}</Text>
      </Column>
      <Column width={1}>
        <Stack space="xxsmall" invertSpace={true}>
          <Box
            background={shiftBackground}
            border="1px solid"
            borderColor="lowerEmphasis"
            borderRadius="small"
            height={barHeight}
            width={1}
          />
          {getBreakProgressBars().map((x, index) => (
            <Box
              key={index}
              backgroundColor="highestBlend"
              border="1px solid"
              borderRadius="small"
              height={barHeight}
              width={`${x.endPercentage - x.startPercentage}%`}
              marginLeft={`${x.startPercentage}%`}
            />
          ))}
        </Stack>
      </Column>
      <Column align="left" width="75px">
        <Text prefab="caption">{formatTime(finishTime)}</Text>
      </Column>
    </Columns>
  )
}

export default React.memo(ShiftProgressBar)
