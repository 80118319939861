import React from 'react'

const ShiftBreak = () => (
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
      fill="currentColor"
    />
    <path
      d="M41.041 24.6665C35.137 25.6412 33.8184 24.3239 32.4997 22.9985C31.181 24.3199 29.8664 25.6372 23.957 24.6665"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.0761 28.9052L41.0334 24.6052C41.7565 21.4581 39.859 18.3031 36.7401 17.4666C35.3516 17.1357 33.9273 16.9789 32.5001 16.9999C31.0751 16.9795 29.6531 17.1362 28.2668 17.4666C25.1466 18.3031 23.2488 21.4598 23.9734 24.6079L24.9308 28.9079"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5002 41.6546V43.0026C22.4763 43.5256 22.7941 44.0036 23.2855 44.184L32.5002 47V35.2226L23.8669 32.0573C23.52 31.9636 23.1492 32.0467 22.8755 32.2796C22.6019 32.5125 22.4605 32.8652 22.4975 33.2226V35.6706"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.5173 41.4586V43.0026C42.5414 43.5252 42.2242 44.0032 41.7333 44.184L32.5 47V35.2226L41.148 32.0573C41.4949 31.9636 41.8657 32.0467 42.1393 32.2796C42.413 32.5125 42.5544 32.8652 42.5173 33.2226V35.876"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4827 40.4746C24.4827 41.1412 23.732 41.6746 22.8147 41.6666C20.9774 41.6586 19.4934 40.5772 19.5 39.2532V38.0532C19.5 36.7279 21 35.6612 22.8334 35.6692C23.752 35.6692 24.4934 36.2146 24.4907 36.8772L24.4827 40.4746Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.5092 40.4746C39.5092 41.1412 40.2599 41.6746 41.1799 41.6666C43.0199 41.6586 44.5066 40.5772 44.4999 39.2532V38.0532C44.4999 36.7279 42.9972 35.6612 41.1572 35.6692C40.2386 35.6692 39.4946 36.2146 39.4986 36.8772L39.5092 40.4746Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M29.5 29V30" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M35.5 29V30" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default ShiftBreak
