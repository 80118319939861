import React from 'react'

import { Box, Button, Card, Heading, Inline, Modal, Stack, Text } from '@myxplor/stardust'

import CloseIcon from '~/icons/Close'

interface Props {
  isOpen: boolean
  onSubmit(): void
  onCancel(): void
}

const EarlyFinishModal = ({ isOpen, onSubmit, onCancel }: Props) => {
  return (
    <Modal isOpen={isOpen} onBlur={onCancel}>
      <Card>
        <Box width="440px" padding="large">
          <Box color="lowEmphasis" padding="small" position="absolute" right={0} top={0} onClick={onCancel}>
            <CloseIcon />
          </Box>

          <Stack space="medium">
            <Heading level={6}>Finish Early</Heading>
            <Text color="highestEmphasis" prefab="body">
              You are finishing your shift before the rostered end time.
            </Text>
            <Text color="highestEmphasis" prefab="body">
              Are you sure you want to finish early?
            </Text>

            <Box paddingTop="large" width={1}>
              <Inline align="right" space="small">
                <Button color="secondary" variant="outline" onClick={onCancel}>
                  Cancel
                </Button>
                <Button color="secondary" onClick={onSubmit}>
                  Finish Early
                </Button>
              </Inline>
            </Box>
          </Stack>
        </Box>
      </Card>
    </Modal>
  )
}

export default React.memo(EarlyFinishModal)
