import React, { ReactNode } from 'react'

import { Box, BackgroundImage } from '@myxplor/stardust'

interface Props {
  children: ReactNode
  image: string
}

const HeroImageLayout = ({ children, image }: Props) => (
  <Box display="flex" height="100vh" width="100vw">
    {/* Horizontal breakpoint container */}
    <Box
      alignItems={['center', 'center', 'flex-start']}
      backgroundColor="foreground"
      display="flex"
      height="100%"
      justifyContent="center"
      minWidth={432}
      paddingX={['medium', 'xxlarge']}
      paddingY={['medium', 'large']}
      width={[1, 1 / 3]}
      zIndex={1}>
      <Box height="100%" maxWidth={432} width={1}>
        {children}
      </Box>
    </Box>

    {/* Background Image RHS Container */}
    <Box width={[0, 1]}>
      <BackgroundImage alt="Xplor Hub Holding" height="100%" image={image} width={1} />
    </Box>
  </Box>
)

export default HeroImageLayout
