import React from 'react'

const ContactDetails = () => (
  <svg width="128" height="98" viewBox="0 0 128 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M112.889 4H15.1111C8.97444 4 4 8.85458 4 14.8434V83.1566C4 89.1454 8.97444 94 15.1111 94H30.6667V90.4152C28.6556 88.6596 27.3756 86.1277 27.3344 83.2965C27.2578 78.0505 31.6567 73.5548 37.0311 73.4008C42.6922 73.2393 47.3333 77.6688 47.3333 83.1566C47.3333 86.0442 46.0433 88.6314 44 90.4152V94H84V90.4152C81.9889 88.6596 80.7089 86.1277 80.6678 83.2965C80.5911 78.0505 84.99 73.5548 90.3644 73.4008C96.0256 73.2393 100.667 77.6688 100.667 83.1566C100.667 86.0442 99.3767 88.6314 97.3333 90.4152V94H112.889C119.026 94 124 89.1454 124 83.1566V14.8434C124 8.85458 119.026 4 112.889 4Z"
      fill="#E8EDF3"
      stroke="#444B54"
      strokeWidth="6.66667"
      strokeLinejoin="round"
    />
    <path
      d="M120.666 15.8301V14.8423C120.666 10.6568 117.176 7.25195 112.888 7.25195H15.1103C10.8225 7.25195 7.33252 10.6568 7.33252 14.8423V83.1556C7.33252 83.8192 7.42919 84.4589 7.59363 85.0727C14.0203 50.0659 47.4003 15.8301 83.4436 15.8301H120.666Z"
      fill="#F6F6FA"
    />
    <path d="M75.1108 30.0229H106.222" stroke="#B5C7E1" strokeWidth="6.66667" strokeLinecap="round" />
    <path d="M75.1108 43.0342H106.222" stroke="#B5C7E1" strokeWidth="6.66667" strokeLinecap="round" />
    <path d="M75.1108 56.0498H92.8886" stroke="#B5C7E1" strokeWidth="6.66667" strokeLinecap="round" />
    <path
      d="M54.0026 31.018C54.0026 41.9091 48.5303 47.372 41.7803 47.372C35.0303 47.372 29.5581 41.9091 29.5581 31.018C29.5581 24.4794 35.0303 19.1792 41.7803 19.1792C48.5303 19.1792 54.0026 24.4794 54.0026 31.018Z"
      fill="#EFD5BE"
    />
    <path
      d="M41.7798 47.374C26.2242 47.374 21.7798 60.3861 21.7798 60.3861V63.6391H61.7798V60.3861C61.7798 60.3861 56.2242 47.374 41.7798 47.374Z"
      fill="#B5C7E1"
    />
    <path
      d="M21.7798 60.3861C21.7798 60.3861 26.2242 47.374 41.7798 47.374C56.2242 47.374 61.7798 60.3861 61.7798 60.3861"
      stroke="#444B54"
      strokeWidth="6.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.0025 31.018C54.0025 41.9091 48.5303 47.372 41.7803 47.372C35.0303 47.372 29.5581 41.9091 29.5581 31.018C29.5581 24.4794 35.0303 19.1792 41.7803 19.1792C48.5303 19.1792 54.0025 24.4794 54.0025 31.018Z"
      stroke="#444B54"
      strokeWidth="6.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.221 59.3C108.062 59.3 109.554 57.8435 109.554 56.047C109.554 54.2504 108.062 52.7939 106.221 52.7939C104.38 52.7939 102.888 54.2504 102.888 56.047C102.888 57.8435 104.38 59.3 106.221 59.3Z"
      fill="#B5C7E1"
    />
  </svg>
)

export default ContactDetails
