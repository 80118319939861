import React, { useEffect, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Alert, Text, PageHeading, Stack, Loading } from '@myxplor/stardust'

import * as Analytics from '~/helpers/analytics'
import { State } from '~/pages/ChildAttendance/common'
import { Container, Header, Content } from '~/layouts/FixedHeaderLayout'

import SessionCard from './SessionCard'
import NoSessions from './NoSessions'
import ErrorDialog from '~/components/ErrorDialog'

const SIGN_IN_ERROR_MESSAGE =
  'Something went wrong during sign in. Please try again, and if the problem persists talk to your centre administrator.'

interface Props extends RouteComponentProps<{ childId: string }> {
  service: Xplor.Service
}

const CasualSessions = ({ service, childId }: Props) => {
  const [casualSessions, setCasualSessions] = useState<Xplor.SuggestedBooking[]>([])
  const [signInOutError, setSignInOutError] = useState<Nullable<string>>(null)
  const { request } = useFetch()
  const [isLoading, setLoading] = useState(true)

  const getKey = (casualSession: Xplor.SuggestedBooking) => {
    return `${casualSession.room_id}-${casualSession.fee_id}`
  }

  const onSignIn = async (cb: Xplor.SuggestedBooking) => {
    const params = {
      child_id: childId ? parseInt(childId) : null,
      room_id: cb.room_id,
      fee_id: cb.fee_id,
    }

    const response = await request.post(`/api/parent/bookings/${service.id}`, params)

    if (response === undefined || response.errors) {
      setSignInOutError(SIGN_IN_ERROR_MESSAGE)
    } else {
      Analytics.parentCasualBooking()
      Analytics.parentBookingSignIn()
      navigate(`/attendance?${State.ActionSignInCasual}=${params.child_id}`)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const response = await request.get(`/api/parent/bookings/${service.id}/suggestions?child_id=${childId}`)
      setCasualSessions(response.data)
      setLoading(false)
    }

    fetchData()
  }, [childId, service])

  return (
    <Container>
      <Header>
        <PageHeading linkColor="primary" overline="Attendance" onBack={() => navigate('/attendance')}>
          Choose Session
        </PageHeading>
      </Header>

      <Content withHeader>
        <ErrorDialog message={signInOutError} onConfirm={() => navigate('/attendance')} />
        {isLoading ? (
          <Loading size="large" />
        ) : (
          <Stack space="small">
            {casualSessions.length > 0 ? (
              <Stack space="small">
                <Alert tone="informative">
                  You may be charged an additional fee by your centre for signing in without a prior booking.
                </Alert>
                <Text color="highestEmphasis" prefab="overline">
                  available sessions
                </Text>
                <Stack space="small">
                  {casualSessions.map(casualSession => (
                    <SessionCard
                      key={getKey(casualSession)}
                      casualSession={casualSession}
                      onSignIn={onSignIn}
                    />
                  ))}
                </Stack>
              </Stack>
            ) : (
              <Stack align="center">
                <NoSessions />
              </Stack>
            )}
          </Stack>
        )}
      </Content>
    </Container>
  )
}

export default React.memo(CasualSessions)
