import React from 'react'

const Login = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.25 18.7542V20.2542C20.25 21.0826 19.5784 21.7542 18.75 21.7542H14.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.25 3.75421H18.75C19.5784 3.75421 20.25 4.42578 20.25 5.25421V6.75421"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.394 23.2461L1.394 21.9601C1.02444 21.9077 0.749803 21.5913 0.75 21.2181V3.72507C0.749709 3.37113 0.996901 3.06516 1.343 2.99107L10.343 0.770072C10.5646 0.722642 10.7957 0.777913 10.9718 0.920447C11.1479 1.06298 11.2502 1.2775 11.25 1.50407V22.5041C11.2499 22.7214 11.1555 22.928 10.9912 23.0703C10.827 23.2127 10.6091 23.2768 10.394 23.2461Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 9.75296L15 12.753H23.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 15.753L15 12.753"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.87499 11.629C7.72295 11.629 7.58597 11.7208 7.52817 11.8615C7.47037 12.0021 7.50319 12.1637 7.61127 12.2706C7.71935 12.3775 7.8813 12.4086 8.02129 12.3493C8.16128 12.29 8.25161 12.1521 8.24999 12C8.25026 11.9005 8.21083 11.805 8.14045 11.7346C8.07007 11.6642 7.97453 11.6248 7.87499 11.625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Login
