import React, { ReactNode } from 'react'
import { navigate } from '@reach/router'

import { Box, Card, Column, Columns, Heading, Stack, Text } from '@myxplor/stardust'

interface Props {
  description: string
  icon: ReactNode
  label: string
  path: string
}
const NavLink = ({ description, icon, label, path }: Props) => (
  <Card elevation={2} onClick={() => navigate(path)}>
    <Box paddingX="large" paddingY="medium">
      <Columns alignY="center" space="large">
        <Column width="fill">
          <Stack space="xxsmall">
            <Heading color="primary" level={5} weight="bold">
              {label}
            </Heading>
            <Text color="highEmphasis" prefab="bodyAlt" weight="medium">
              {description}
            </Text>
          </Stack>
        </Column>

        <Column>
          <Box color="primary">{icon}</Box>
        </Column>
      </Columns>
    </Box>
  </Card>
)

export default React.memo(NavLink)
