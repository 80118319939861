import React, { useState } from 'react'
import { navigate } from '@reach/router'

import {
  Anchor,
  Avatar,
  Box,
  Card,
  Column,
  Columns,
  Heading,
  Image,
  Inline,
  Stack,
  Text,
  useTheme,
} from '@myxplor/stardust'

import { formatTime } from '~/helpers/dates'
import { State } from '~/pages/ChildAttendance/common'

import LoginIcon from '~/icons/Login'

import lightCheckRocketImage from '~/assets/images/light_check_rocket.gif'
import darkCheckRocketImage from '~/assets/images/dark_check_rocket.gif'

function formatBookingTime(booking: Xplor.Booking) {
  return `${formatTime(booking.start_time)} - ${formatTime(booking.finish_time)}`
}

function getInitialState(booking: Nullable<Xplor.Booking>, initialState: Nullable<State>) {
  if (initialState) {
    return initialState
  }
  if (booking) {
    if (!booking.attendance_check_in_time) {
      return State.Pending
    } else if (booking.attendance_check_out_time) {
      return State.SignedOut
    } else if (booking.attendance_check_in_time) {
      return State.SignedIn
    }
  }
  return State.NoBooking
}

interface Props {
  booking?: Xplor.Booking
  child: Xplor.Child
  onSignIn(booking: Xplor.Booking): void
  onSignOut(booking: Xplor.Booking): void
  initialState: Nullable<State>
}

const ChildCard = ({ booking, child, onSignIn, onSignOut, initialState }: Props) => {
  const nameSegments = child.name.split(' ')
  const initials = nameSegments.map(x => x[0]).join('')

  const [state, setState] = useState<State>(getInitialState(booking, initialState))

  const { name: themeName } = useTheme()
  const attendanceIcon = themeName === 'dark' ? darkCheckRocketImage : lightCheckRocketImage

  const handleCasual = () => {
    navigate(`/casual/${child.id}`)
  }

  const handleSignIn = () => {
    setState(State.ActionSignIn)
    booking && onSignIn(booking)
  }

  const handleSignOut = () => {
    setState(State.ActionSignOut)
    booking && onSignOut(booking)
  }

  const [label, color, callback]: any = {
    [State.NoBooking]: ['Casual Session', 'primary', handleCasual],
    [State.Pending]: ['Sign In', 'primary', handleSignIn],
    [State.ActionSignIn]: ['Signed In', 'secondary', undefined],
    [State.ActionSignInCasual]: ['Signed In', 'secondary', undefined],
    [State.ActionSignOut]: ['Signed Out', 'secondary', undefined],
    [State.SignedIn]: ['Sign Out', 'primary', handleSignOut],
    [State.SignedOut]: ['Sign In', 'primary', handleSignIn],
  }[state]

  const activeBooking = [State.ActionSignIn, State.ActionSignInCasual, State.SignedIn].includes(state)

  return (
    <Box paddingLeft="large">
      <Box
        border="1px solid transparent"
        borderColor={activeBooking ? 'primary' : 'transparent'}
        borderRadius="small"
        transition="border-color 0.2s">
        <Card>
          <Box padding="small">
            <Columns space="small">
              <Column>
                <Box marginLeft="-48px">
                  <Avatar image={child.image} label={initials} size="large" />
                </Box>
              </Column>

              <Column width="fill">
                <Stack space="xsmall">
                  <Stack space="xxxsmall">
                    {nameSegments.map(name => (
                      <Heading key={name} level={5}>
                        {name}
                      </Heading>
                    ))}
                  </Stack>
                  {booking ? (
                    <Stack space="xxxsmall">
                      <Text prefab="subtitleAlt">{formatBookingTime(booking)}</Text>
                      <Text prefab="subtitleAlt">{booking.room_name}</Text>
                    </Stack>
                  ) : (
                    <Text prefab="bodyAlt">No booking</Text>
                  )}
                </Stack>
              </Column>
            </Columns>
          </Box>

          <Box padding="small" position="absolute" bottom={0} right={0} width={1}>
            <Anchor color={color} onClick={callback}>
              <Inline align="right" alignY="center" space="xxsmall" wrap={false}>
                {state === State.NoBooking && <LoginIcon />}
                {state === State.Pending && <LoginIcon />}
                {state === State.SignedIn && <LoginIcon />}
                {state === State.ActionSignIn && <Image src={attendanceIcon} />}
                {state === State.ActionSignInCasual && <Image src={attendanceIcon} />}
                {state === State.ActionSignOut && <Image src={attendanceIcon} />}
                {state === State.SignedOut && <LoginIcon />}
                {label}
              </Inline>
            </Anchor>
          </Box>
        </Card>
      </Box>
    </Box>
  )
}

export default React.memo(ChildCard)
