import React from 'react'

import { Box, Loading, Modal } from '@myxplor/stardust'

interface Props {
  isOpen: boolean
}

const LoadingModal = ({ isOpen }: Props) => (
  <Modal isOpen={isOpen} onBlur={() => null}>
    <Box
      backgroundColor="foreground"
      borderRadius="circular"
      boxShadow="12dp"
      height="300px"
      overflow="hidden"
      width="300px">
      <Box
        position="absolute"
        left="50%"
        height="512px"
        top="50%"
        transform="translate(-50%, -50%)"
        width="512px">
        <Loading size="large" />
      </Box>
    </Box>
  </Modal>
)

export default React.memo(LoadingModal)
