import React from 'react'

import { Box, Button, Card, Heading, Modal, Stack, Text } from '@myxplor/stardust'

interface Props {
  message: Nullable<string>
  onConfirm(): void
}

const ErrorDialog = ({ message, onConfirm }: Props) => (
  <Modal isOpen={!!message} onBlur={onConfirm}>
    <Box maxWidth="550px">
      <Card>
        <Box padding="large">
          <Stack space="xsmall">
            <Heading level={6}>Unxpected Error</Heading>

            <Text prefab="body">{message}</Text>

            <Stack align="right">
              <Box paddingTop="large">
                <Button onClick={onConfirm}>Confirm</Button>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Card>
    </Box>
  </Modal>
)

export default React.memo(ErrorDialog)
