import React, { ReactNode, useState } from 'react'

import { Alert, Box, Button, Form, Heading, Inline, Popup, Stack, Text, TextField } from '@myxplor/stardust'

import { isStringEmpty } from '~/helpers/utils'

interface Props {
  children: ReactNode
  isLoading: boolean
  onSubmit(email: string): Promise<string | void>
}

const ForgotPasswordModal = ({ children, isLoading, onSubmit }: Props) => {
  const [email, setEmail] = useState<string>('')
  const [errors, setErrors] = useState<Nullable<string>>(null)
  const [message, setMessage] = useState<Nullable<string>>(null)
  const [showResetInput, setShowResetInput] = useState<boolean>(false)

  const attemptSubmit = async () => {
    if (isStringEmpty(email)) {
      return setErrors('Please enter your email')
    }

    setErrors(null)
    setMessage(null)

    const error = await onSubmit(email)
    if (!error) {
      setShowResetInput(false)
      setMessage('Please check your email')
    } else {
      setErrors(error)
    }
  }

  const onEmailChange = (value: string) => {
    setEmail(value)
    errors && setErrors(null)
  }

  const onReset = () => {
    setEmail('')
    setErrors(null)
    setMessage(null)
    setShowResetInput(true)
  }

  return (
    <Popup activator={children} direction="above" showClose onClose={onReset}>
      <Box padding="large" width="440px">
        <Stack space="medium">
          <Heading level={6}>Forgot Password</Heading>
          <Text color="highestEmphasis" prefab="body">
            Your centre username is different to your administrator login and can be found under the service
            settings on Xplor Office.
          </Text>
          <Text color="highestEmphasis" prefab="body">
            Resetting your password will also logout Educators using Xplor Playground.
          </Text>

          {showResetInput ? (
            <Form onSubmit={attemptSubmit}>
              <Stack space="medium">
                <TextField onChange={onEmailChange} label="Email" />

                {errors && <Alert tone="negative">{errors}</Alert>}
                {message && <Alert tone="informative">{message}</Alert>}

                <Inline align="right">
                  <Button disabled={isLoading} type="submit" variant="outline">
                    Send Reset Code
                  </Button>
                </Inline>
              </Stack>
            </Form>
          ) : (
            <Stack space="medium">
              {message && <Alert tone="informative">{message}</Alert>}

              <Inline align="right">
                <Button onClick={onReset}>Reset Password</Button>
              </Inline>
            </Stack>
          )}
        </Stack>
      </Box>
    </Popup>
  )
}

export default React.memo(ForgotPasswordModal)
