import { useState } from 'react'
import { navigate } from '@reach/router'
import useFetch from 'use-http'

import * as Analytics from '~/helpers/analytics'
import * as Auth from '~/helpers/auth'
import * as HubMessages from '~/helpers/hubMessages'

import useLocalStorage, { StorageKey } from '~/hooks/useLocalStorage'

function useRoleAuth() {
  const [activeRole, setActiveRole] = useState<Nullable<Xplor.Role>>(null)
  const [roles, setRoles] = useState<Xplor.Role[]>([])
  const [messages, setMessages] = useState<Xplor.Message[]>([])

  const [, setEducatorToken, deleteEducatorToken] = useLocalStorage(StorageKey.EducatorAuthToken)
  const [, setParentToken, deleteParentToken] = useLocalStorage(StorageKey.ParentAuthToken)
  const [, setStargateToken, deleteStargateToken] = useLocalStorage(StorageKey.StargateAuthToken)

  const { request } = useFetch()

  // Clear active role
  const onClearRole = () => {
    setActiveRole(null)
  }

  // Clear all current login data
  const onRoleLogout = () => {
    deleteEducatorToken()
    deleteParentToken()
    deleteStargateToken()

    setActiveRole(null)
    setRoles([])
  }

  const onUseParentRole = async (role: Xplor.Role, token: string, service: Xplor.Service) => {
    setActiveRole(role)
    setParentToken(token)

    Analytics.setRole(role.user_type)

    const hubMessages = await HubMessages.getHubMessages(request, service)

    if (hubMessages.length > 0) {
      setMessages(hubMessages)
      navigate('/messages')
    } else {
      navigate('/attendance')
    }
  }

  const onUseEducatorRole = (role: Xplor.Role, token: string) => {
    setActiveRole(role)
    setEducatorToken(token)
    navigate('/shift')

    Analytics.setRole(role.user_type)
  }

  // Takes a list of auth tokens from Hub2/Hodor/Stargate and turns them into
  // roles that the user can authenticate as. If there is only one role, automatically
  // use it. If a Stargate token comes back, need to check what user contexts are
  // available
  const onRoleAuth = async (tokens: Xplor.Token[], service: Xplor.Service) => {
    const stargateToken = Auth.findStargateToken(tokens)

    stargateToken && setStargateToken(stargateToken.token)

    const stargateContexts = await Auth.getStargateUserContexts(request, service)

    const roleOptions = await Auth.generateRoles(tokens, stargateContexts)

    // Rare case that should only come up if our only credential was a Stargate
    // token, and it had no connected user contexts. Likely only to be found
    // within test data
    if (roleOptions.length === 0) {
      alert('No profiles are connected to this Xplor ID. Returning to dashboard')
      navigate('/service')
      return
    }

    setRoles(roleOptions)

    Analytics.setRoleCount(roleOptions.length)

    if (roleOptions.length === 1) {
      const defaultRole = roleOptions[0]
      const token = await Auth.getTokenForRole(request, defaultRole)

      Auth.isEducator(defaultRole)
        ? onUseEducatorRole(defaultRole, token)
        : onUseParentRole(defaultRole, token, service)
    } else {
      navigate('/role-selection')
    }
  }

  return {
    activeRole,
    roles,
    messages,
    onClearRole,
    onRoleAuth,
    onRoleLogout,
    onUseEducatorRole,
    onUseParentRole,
  }
}

export default useRoleAuth
