import React, { useEffect } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import useFetch from 'use-http'

import { Box, Card, Heading, Image, Inline, PageHeading, Stack, Text } from '@myxplor/stardust'

import * as Auth from '~/helpers/auth'

import { fullName } from '~/helpers/utils'

import { Container, Header, Content } from '~/layouts/FixedHeaderLayout'

import homeIcon from '~/assets/images/home_icon.png'
import playgroundIcon from '~/assets/images/playground_icon.png'

interface Props extends RouteComponentProps {
  roles: Xplor.Role[]
  onClearRole(): void
  onUseEducatorRole(role: Xplor.Role, token: string): void
  onUseParentRole(role: Xplor.Role, token: string, service: Xplor.Service): void
  service: Xplor.Service
}

const RoleSelection = ({ roles, onClearRole, onUseEducatorRole, onUseParentRole, service }: Props) => {
  const { request } = useFetch()

  const onClick = async (role: Xplor.Role) => {
    const token = await Auth.getTokenForRole(request, role)

    Auth.isEducator(role) ? onUseEducatorRole(role, token) : onUseParentRole(role, token, service)
  }

  useEffect(onClearRole, [])

  return (
    <Container>
      <Header>
        <PageHeading linkColor="primary" overline="Dashboard" onBack={() => navigate('/service')}>
          Choose Role
        </PageHeading>
      </Header>

      <Content withHeader>
        <Box paddingTop={roles.length > 3 ? 'none' : 'xxxlarge'}>
          <Inline align="center" space="medium">
            {roles.map(role => (
              <Box key={role.user_id} width="268px">
                <Card onClick={() => onClick(role)}>
                  <Box padding="large">
                    <Stack align="center" space="small">
                      <Image src={role.user_type === 'Educator' ? playgroundIcon : homeIcon} />

                      <Stack align="center" space="xxxsmall">
                        <Text prefab="overline">{role.user_type}</Text>
                        <Heading level={6} lineHeight={1.4} truncate>
                          {fullName(role.first_name, role.last_name)}
                        </Heading>
                        <Text prefab="caption" truncate>
                          {role.identifier}
                        </Text>
                      </Stack>

                      <Text prefab="body">
                        {Auth.isEducator(role) ? 'Start or end your shift' : 'Sign in your child'}
                      </Text>
                    </Stack>
                  </Box>
                </Card>
              </Box>
            ))}
          </Inline>
        </Box>
      </Content>
    </Container>
  )
}

export default React.memo(RoleSelection)
