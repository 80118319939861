import React, { useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import {
  Alert,
  Box,
  Button,
  Card,
  Column,
  Columns,
  Form,
  PageHeading,
  Stack,
  Textarea,
  TextField,
} from '@myxplor/stardust'

import * as Analytics from '~/helpers/analytics'

import { Container, Header, Content } from '~/layouts/FixedHeaderLayout'

import SignOutReminder from './SignOutReminder'

type Props = RouteComponentProps

const VisitorForm = (_props: Props) => {
  const { request } = useFetch()

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<Nullable<string>>(null)
  const [showReminder, setShowReminder] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [visitor, setVisitor] = useState({
    id: null,
    first_name: '',
    last_name: '',
    phone_no: '',
    reason: '',
    address: '',
  })

  const onChange = (field: string, value: string) => {
    setVisitor({ ...visitor, [field]: value })
  }

  const onSubmit = async () => {
    setSubmitted(true)

    if (visitor.phone_no.length < 6) {
      return
    }

    setIsLoading(true)
    const response = await request.post('/api/service/visitors', visitor)

    if (response && response.data) {
      Analytics.visitorLogIn()
      setShowReminder(true)
      setTimeout(() => navigate('/service'), 3000)
    } else {
      setErrors('Please ensure all fields are completed and contain correct information')
    }

    setIsLoading(false)
  }

  const buttonEnabled =
    visitor.first_name && visitor.last_name && visitor.phone_no && visitor.reason && !isLoading

  return (
    <Container>
      <Header>
        <PageHeading linkColor="primary" overline="Visitor Log" onBack={() => navigate('/visitors')}>
          New Visitor
        </PageHeading>
      </Header>

      <Content withHeader>
        <Form onSubmit={onSubmit}>
          <Card>
            <Box padding="large" paddingBottom="xxlarge">
              <Stack space="large">
                <Columns collapseBelow="mobile" space="large">
                  <Column width={1 / 2}>
                    <TextField
                      label="First name"
                      name="first_name"
                      value={visitor.first_name}
                      onChange={(value: string) => onChange('first_name', value)}
                    />
                  </Column>
                  <Column width={1 / 2}>
                    <TextField
                      label="Last name"
                      name="last_name"
                      value={visitor.last_name}
                      onChange={(value: string) => onChange('last_name', value)}
                    />
                  </Column>
                </Columns>
                <TextField
                  label="Phone number"
                  name="phone_no"
                  value={visitor.phone_no}
                  type="phone"
                  invalid={submitted && visitor.phone_no.length < 6}
                  maxLength={50}
                  validationText="Phone number must be at least six digits"
                  onChange={(value: string) => onChange('phone_no', value)}
                />
                <TextField
                  label="Address (optional)"
                  name="address"
                  value={visitor.address}
                  onChange={(value: string) => onChange('address', value)}
                />
                <Textarea
                  label="Reason for visit"
                  name="reason"
                  value={visitor.reason}
                  onChange={(value: string) => onChange('reason', value)}
                />

                {errors && <Alert tone="negative">{errors}</Alert>}
              </Stack>
            </Box>

            <Box display="flex" justifyContent="center" position="absolute" bottom={-24} left={0} right={0}>
              <Box width="200px">
                <Button disabled={!buttonEnabled} fill size="large" type="submit">
                  Sign in
                </Button>
              </Box>
            </Box>
          </Card>
        </Form>
      </Content>

      <SignOutReminder isOpen={showReminder} />
    </Container>
  )
}

export default React.memo(VisitorForm)
