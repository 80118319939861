import React from 'react'

const Feedback = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25123 9.34802C1.24873 10.9848 1.67571 12.5924 2.48626 14.0106L0.0630393 19.104C-0.189733 19.6353 0.364413 20.1899 0.895277 19.9369L5.9837 17.5117C10.3369 20.0063 15.9163 18.6218 18.5866 14.3233C21.318 9.92649 19.971 4.14682 15.5778 1.41324C14.0897 0.487003 12.3712 -0.00248868 10.6174 9.51477e-06C5.45151 -0.0066324 1.25836 4.17809 1.25123 9.34802ZM3.75732 14.2463C3.84879 14.054 3.83614 13.8284 3.72375 13.6476C2.92187 12.3575 2.49771 10.8681 2.50003 9.34999C2.50621 4.86997 6.13992 1.24383 10.6166 1.25001C12.1388 1.2477 13.6285 1.67193 14.9183 2.47477C18.7258 4.84393 19.8932 9.85293 17.526 13.6635C15.1588 17.4741 10.1539 18.6424 6.34643 16.2733C6.16576 16.1608 5.94034 16.1482 5.74827 16.2398L1.94702 18.0514L3.75732 14.2463ZM14.3625 7.49939H6.86953C6.52468 7.49939 6.24512 7.21957 6.24512 6.87439C6.24512 6.52921 6.52468 6.24939 6.86953 6.24939H14.3625C14.7074 6.24939 14.9869 6.52921 14.9869 6.87439C14.9869 7.21957 14.7074 7.49939 14.3625 7.49939ZM5.62075 9.99908H15.6114C15.9563 9.99908 16.2358 9.71926 16.2358 9.37408C16.2358 9.02891 15.9563 8.74908 15.6114 8.74908H5.62075C5.2759 8.74908 4.99634 9.02891 4.99634 9.37408C4.99634 9.71926 5.2759 9.99908 5.62075 9.99908ZM14.3625 12.4988H6.86953C6.52468 12.4988 6.24512 12.219 6.24512 11.8738C6.24512 11.5286 6.52468 11.2488 6.86953 11.2488H14.3625C14.7074 11.2488 14.9869 11.5286 14.9869 11.8738C14.9869 12.219 14.7074 12.4988 14.3625 12.4988Z"
      fill="#00B2A8"
    />
  </svg>
)

export default Feedback
