import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Anchor, Box, Column, Columns, Image, PageHeading, Stack, Toggle, useTheme } from '@myxplor/stardust'

import * as Analytics from '~/helpers/analytics'
import { getHour, getLocalDateTime } from '~/helpers/dates'
import { getNestedProp } from '~/helpers/utils'

import ContextSignInIcon from '~/icons/ContextSignIn'
import VisitorSignInIcon from '~/icons/VisitorSignIn'

import NavLink from './NavLink'
import AppStoreModal from '~/pages/ServiceDashboard/AppStoreModal'

import useLocalStorage, { StorageKey } from '~/hooks/useLocalStorage'

interface Props extends RouteComponentProps {
  service: Xplor.Service
  onSessionEnd(): void
  onServiceLogout(): void
  onThemeToggle(): void
}

const QR_REFRESH_RATE = 600000

const ServiceDashboard = ({ service, onServiceLogout, onSessionEnd, onThemeToggle }: Props) => {
  const [qrCode, setQRCode] = useState<Nullable<string>>(null)
  const [refreshRequest, setRefreshRequest] = useLocalStorage(StorageKey.RefreshRequest)
  const { name: activeTheme } = useTheme()

  const { request } = useFetch()

  const serviceName = service.name
  const currentHour = getHour(getLocalDateTime())
  const greeting = currentHour < 12 ? 'morning' : 'afternoon'

  const updateRefreshRequest = () => request.patch(`/api/service/refresh_request/${service.id}`)

  const actionRefreshRequest = async (currentRefreshRequest: number) => {
    await updateRefreshRequest()
    setRefreshRequest(String(currentRefreshRequest))
    window.location.reload()
  }

  useEffect(() => {
    Analytics.setService(service.id)

    const fetchQRCode = async () => {
      const response = await request.get('/api/service/geolocation')
      if (response && response.data) {
        setQRCode(response.data.token)
      }
    }

    const fetchRefreshRequest = async () => {
      const response = await request.get(`/api/service/refresh_request/${service.id}`)
      const latestRefreshRequest = getNestedProp(response, 'data.refresh_request.id')
      const currentRefreshRequest = parseInt(refreshRequest || '-1')

      if (latestRefreshRequest && latestRefreshRequest > currentRefreshRequest) {
        await actionRefreshRequest(latestRefreshRequest)
      }
    }

    fetchRefreshRequest()
    fetchQRCode()
    onSessionEnd()

    const refreshQRCode = setInterval(async () => {
      await fetchRefreshRequest()
      await fetchQRCode()
    }, QR_REFRESH_RATE)
    return () => clearInterval(refreshQRCode)
  }, [])

  return (
    <Box minHeight="100vh" padding="xxlarge" width={1}>
      <Box paddingX="xxsmall" paddingY="large" width={1}>
        <Stack space={['none', 'none', 'large']}>
          <Columns alignY="center">
            <Column width="fill">
              <PageHeading level={2} overline={service.name}>
                {`Good ${greeting}`}
              </PageHeading>
            </Column>

            <Column>
              <Box paddingRight="large">
                <Box height="64px" width="64px">
                  <Image src={service.image} />
                </Box>
              </Box>
            </Column>
          </Columns>

          <Columns alignY="center" collapseBelow="tablet" space={['xlarge', 'xlarge', 'xxlarge']}>
            <Column align="center">
              <Box paddingY="xxlarge">
                <AppStoreModal token={qrCode} serviceName={serviceName} />
              </Box>
            </Column>

            <Column align="center" width="fill">
              <Stack space="large">
                <NavLink
                  label="Sign In"
                  description="Parents and Educators"
                  icon={<ContextSignInIcon />}
                  path="/login"
                />
                <NavLink
                  label="Visitor Log"
                  description="Sign your name"
                  icon={<VisitorSignInIcon />}
                  path="/visitors"
                />
              </Stack>
            </Column>
          </Columns>
        </Stack>
      </Box>

      <Box backgroundColor="primary" position="absolute" bottom={0} height="88px" left={0} width={1}>
        <Box bottom={0} left={0} padding="small" position="absolute">
          <Anchor color="lowEmphasis" onClick={onServiceLogout}>
            Service sign out
          </Anchor>
        </Box>
        <Box position="absolute" bottom={0} right={0} height="64px" width="64px">
          <Toggle onChange={onThemeToggle} on={activeTheme === 'light'} />
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(ServiceDashboard)
