import { useCallback, useState } from 'react'

export enum StorageKey {
  EducatorAuthToken = 'educatorAuthToken',
  ParentAuthToken = 'parentAuthToken',
  StargateAuthToken = 'stargateAuthToken',
  ServiceAuthToken = 'serviceAuthToken',
  RefreshRequest = 'refreshRequest',
  Theme = 'THEME',
}

function useLocalStorage(key: string): [Nullable<string>, (value: string) => void, () => void] {
  const [value, setValueInternal] = useState(localStorage.getItem(key))

  const deleteValue = useCallback(() => {
    localStorage.removeItem(key)
    setValueInternal(null)
  }, [])

  const setValue = useCallback(
    (newValue: string) => {
      localStorage.setItem(key, newValue)
      setValueInternal(newValue)
    },
    [key, setValueInternal]
  )

  return [value, setValue, deleteValue]
}

export default useLocalStorage
