import React from 'react'

import { Box, Card } from '@myxplor/stardust'

type Props = { children: any }

const DialogLayout = ({ children }: Props) => (
  <Box alignItems="center" display="flex" height="100vh" justifyContent="center" width="100vw">
    <Box maxWidth={['100%', '450px']} width={1}>
      <Card>
        <Box padding={['small', 'large']}>{children}</Box>
      </Card>
    </Box>
  </Box>
)

export default DialogLayout
