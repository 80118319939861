type TokenType = 'educator' | 'parent' | 'service' | 'stargate'

// Defines which auth token to use for which endpoint(s)
const authMapping: { [path: string]: Nullable<string> } = {
  '/api/educator': 'educator',
  '/api/parent': 'parent',
  '/api/service': 'service',
  '/api/stargate': 'stargate',
}

// Given a path, find the auth token to use
function findTokenForRoute(route: string) {
  const candidates: Nullable<TokenType>[] = []
  Object.keys(authMapping).forEach((key: string) => {
    if (route.startsWith(key)) {
      candidates.push(authMapping[key] as TokenType)
    }
  })

  // Find key in local storage if there's a match
  return candidates[0] ? localStorage.getItem(`${candidates[0]}AuthToken`) : null
}

const interceptors = {
  request: async (options: any, _url: string, _path: string, route: string) => {
    const token = findTokenForRoute(route)
    if (token) {
      options.headers = options.headers || {}
      options.headers['Authorization'] = `Bearer ${token}`
      options.headers['Accept'] = 'application/json'
      options.headers['Content-Type'] = 'application/json'
    }
    return options
  },

  response: (response: any) => {
    return response
  },
}

export default { cachePolicy: 'network-only' as any, interceptors }
