import React from 'react'

const ShiftEnd = () => (
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 46.9973C40.2843 46.9973 47 40.2816 47 31.9973C47 23.713 40.2843 16.9973 32 16.9973C23.7157 16.9973 17 23.713 17 31.9973C17 40.2816 23.7157 46.9973 32 46.9973Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 27.7473C27 27.3331 27.3358 26.9973 27.75 26.9973H36.25C36.6642 26.9973 37 27.3331 37 27.7473V36.2473C37 36.6615 36.6642 36.9973 36.25 36.9973H27.75C27.3358 36.9973 27 36.6615 27 36.2473V27.7473Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ShiftEnd
