import React from 'react'

import { Box, Button, Card, Heading, Inline, Modal, Stack, Textarea, Form } from '@myxplor/stardust'

import CloseIcon from '~/icons/Close'

interface Props {
  comment: string
  isOpen: boolean
  onSubmit(): void
  onChange(comment: string): void
  onCancel(): void
}

const ShiftCommentModal = ({ comment, isOpen, onSubmit, onChange, onCancel }: Props) => {
  return (
    <Modal isOpen={isOpen} onBlur={onCancel}>
      <Form onSubmit={onSubmit}>
        <Card>
          <Box width="440px" padding="large">
            <Box color="lowEmphasis" padding="small" position="absolute" right={0} top={0} onClick={onCancel}>
              <CloseIcon />
            </Box>

            <Stack space="medium">
              <Heading level={6}>Shift Comment</Heading>
              <Textarea
                color="secondary"
                label="Write a comment about your shift"
                name="comment"
                value={comment}
                onChange={(value: string) => onChange(value)}
              />
              <Box width={1}>
                <Inline align="right" space="xsmall">
                  <Button color="secondary" variant="outline" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button type="submit" color="secondary">
                    Save
                  </Button>
                </Inline>
              </Box>
            </Stack>
          </Box>
        </Card>
      </Form>
    </Modal>
  )
}

export default React.memo(ShiftCommentModal)
