import React, { ReactNode } from 'react'

import { Box, Heading, Popup, Stack, Text } from '@myxplor/stardust'

interface Props {
  children: ReactNode
}

const NoAccountModal = ({ children }: Props) => {
  return (
    <Popup activator={children} direction={['below', 'right']} showClose>
      <Box padding="large" paddingBottom="xxlarge" width="440px">
        <Stack space="medium">
          <Heading level={6}>No Xplor Account</Heading>
          <Text color="highestEmphasis" prefab="body">
            Try checking your email for your Xplor invitation, or ask the parents of the child to create a
            Guardian account for you.
          </Text>
          <Text color="highestEmphasis" prefab="body">
            Otherwise, a friendly staff member will be able to help you sign-in the child.
          </Text>
        </Stack>
      </Box>
    </Popup>
  )
}

export default React.memo(NoAccountModal)
