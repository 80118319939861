import React, { useEffect, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Button, Column, Columns, Loading, Heading, PageHeading } from '@myxplor/stardust'

import * as Analytics from '~/helpers/analytics'

import { Container, Header, Footer } from '~/layouts/FixedHeaderLayout'
import { isLaterDate, getLocalDateTime } from '~/helpers/dates'
import { ShiftState } from './interfaces'
import NoShift from './NoShift'
import ShiftCommentModal from './ShiftCommentModal'
import ShiftDashboard from './ShiftDashboard'
import EarlyFinishModal from './EarlyFinishModal'

interface Props extends RouteComponentProps {
  activeRole: Xplor.Role
  roles: Xplor.Role[]
  service: Xplor.Service
}

const EmployeeShift = ({ activeRole, roles, service }: Props) => {
  const [isLoading, setLoading] = useState(true)
  const [isUpdating, setUpdating] = useState(false)
  const [shift, setShift] = useState<Nullable<Xplor.Shift>>(null)
  const [shiftState, setShiftState] = useState<Nullable<ShiftState>>(null)
  const [showComment, setShowComment] = useState(false)
  const [comment, setComment] = useState(shift?.comment)
  const [showEarlyFinish, setShowEarlyFinish] = useState(false)

  const { request } = useFetch()

  const userName = `${activeRole.first_name} ${activeRole.last_name}`

  const resetComment = () => setComment(shift?.comment)

  const onToggleCommentMask = () => {
    if (!showComment) {
      Analytics.educatorShiftComment(false)
      resetComment()
    }

    setShowComment(!showComment)
  }

  const onToggleEarlyFinishMask = () => {
    setShowEarlyFinish(!showEarlyFinish)
  }

  const finishShiftQuery = async () => {
    if (!shift) return

    if (shift.break_status === 'CHECKED_IN') {
      const finishBreakQuery = await request.patch(
        `/api/educator/shift/${service.id}/${shift.id}/break_finish/`
      )
      if (finishBreakQuery && finishBreakQuery.data) {
        Analytics.educatorShiftBreakEnd()
        setShift(finishBreakQuery.data)
      }
    }

    const finishShiftQuery = await request.patch(`/api/educator/shift/${service.id}/${shift.id}/check_out/`)
    if (finishShiftQuery && finishShiftQuery.data) {
      Analytics.educatorShiftCheckOut()
      setShift(finishShiftQuery.data)
      setUpdating(false)
    }
  }

  const handleShiftAction = async (action: string) => {
    if (!shift) return

    setUpdating(true)

    switch (action) {
      case 'start-shift': {
        const startShiftQuery = await request.patch(`/api/educator/shift/${service.id}/${shift.id}/check_in/`)
        if (startShiftQuery && startShiftQuery.data) {
          Analytics.educatorShiftCheckIn()
          setShift(startShiftQuery.data)
          setUpdating(false)
        }
        return
      }
      case 'finish-shift': {
        if (isLaterDate(shift.finish_time, getLocalDateTime())) {
          onToggleEarlyFinishMask()
          setUpdating(false)
        } else finishShiftQuery()
        return
      }
      case 'start-break-enabled': {
        const startBreakQuery = await request.patch(
          `/api/educator/shift/${service.id}/${shift.id}/break_start/`
        )
        if (startBreakQuery && startBreakQuery.data) {
          Analytics.educatorShiftBreakStart()
          setShift(startBreakQuery.data)
          setUpdating(false)
        }
        return
      }
      case 'finish-break': {
        const finishBreakQuery = await request.patch(
          `/api/educator/shift/${service.id}/${shift.id}/break_finish/`
        )
        if (finishBreakQuery && finishBreakQuery.data) {
          Analytics.educatorShiftBreakEnd()
          setShift(finishBreakQuery.data)
          setUpdating(false)
        }
        return
      }
    }
  }

  const shiftStateMachine = () => {
    if (!shift) return

    switch (shift.shift_status) {
      case 'NONE':
        setShiftState({
          action1: 'start-shift',
          action2: 'start-break-disabled',
        })
        return
      case 'CHECKED_IN':
        if (shift.break_status === 'NONE' || shift.break_status === 'CHECKED_OUT') {
          setShiftState({
            action1: 'finish-shift',
            action2: 'start-break-enabled',
          })
          return
        } else if (shift.break_status === 'CHECKED_IN') {
          setShiftState({
            action1: 'finish-shift',
            action2: 'finish-break',
          })
          return
        }
        return
      case 'CHECKED_OUT':
        if (shift.break_status === 'CHECKED_OUT' || shift.break_status === 'NONE') {
          setShiftState({
            action1: 'start-shift',
            action2: 'start-break-disabled',
          })
        }
        return
    }
  }

  const onSubmitComment = async () => {
    onToggleCommentMask()
    setUpdating(true)

    const params = { comment: comment }
    const response = await request.patch(
      `/api/educator/shift/${service.id}/${shift?.id}/save_comment`,
      params
    )
    if (response && response.data) {
      Analytics.educatorShiftComment(true)
      setShift(response.data)
      setUpdating(false)
    }
  }

  const onSubmitEarlyFinish = async () => {
    onToggleEarlyFinishMask()
    finishShiftQuery()
  }

  useEffect(() => {
    const fetchShiftData = async () => {
      const response = await request.get(`/api/educator/shift/${service.id}`)
      if (response && response.data) {
        setShift(response.data)
        setLoading(false)
      } else {
        setShift(null)
        setLoading(false)
      }
    }

    fetchShiftData()
  }, [])

  useEffect(() => {
    shiftStateMachine()
    resetComment()
  }, [shift])

  const displayShift = () => {
    if (shift && shiftState) {
      return (
        <ShiftDashboard
          shift={shift}
          shiftState={shiftState}
          isUpdating={isUpdating}
          onShiftAction={handleShiftAction}
          onShowComment={onToggleCommentMask}
        />
      )
    } else {
      return <NoShift />
    }
  }

  return (
    <Container>
      <Header>
        <PageHeading
          linkColor="secondary"
          overline={roles.length > 1 ? 'Choose role' : 'Dashboard'}
          onBack={() => navigate(roles.length > 1 ? '/role-selection' : '/service')}>
          Shift
        </PageHeading>
      </Header>

      {isLoading ? <Loading size="large" /> : displayShift()}

      <Footer>
        <Columns alignY="center" space="small">
          <Column width="fill">
            <Heading level={6} weight="regular">
              {userName}
            </Heading>
          </Column>
          <Column>
            <Button color="secondary" type="submit" onClick={() => navigate('/service')}>
              Log Out
            </Button>
          </Column>
        </Columns>
      </Footer>

      <ShiftCommentModal
        comment={comment || ''}
        isOpen={showComment}
        onSubmit={onSubmitComment}
        onChange={setComment}
        onCancel={onToggleCommentMask}
      />

      <EarlyFinishModal
        isOpen={showEarlyFinish}
        onSubmit={onSubmitEarlyFinish}
        onCancel={onToggleEarlyFinishMask}
      />
    </Container>
  )
}

export default React.memo(EmployeeShift)
