import { useCallback, useEffect, useState } from 'react'
import useFetch from 'use-http'

import useLocalStorage, { StorageKey } from '~/hooks/useLocalStorage'

function useServiceAuth() {
  const [service, setService] = useState<Nullable<Xplor.Service>>(null)
  const [serviceToken, setServiceToken, deleteServiceToken] = useLocalStorage(StorageKey.ServiceAuthToken)
  const [, , deleteRefreshRequest] = useLocalStorage(StorageKey.RefreshRequest)

  const { request } = useFetch()

  useEffect(() => {
    const fetchService = async () => {
      const response = await request.get('/api/service')
      if (response && response.data) {
        setService(response.data)
      }
    }

    if (serviceToken) {
      fetchService()
    }
  }, [serviceToken])

  const onServiceLogout = useCallback(() => {
    setService(null)
    deleteServiceToken()
    deleteRefreshRequest()
  }, [deleteServiceToken])

  return { service, onServiceAuth: setServiceToken, onServiceLogout }
}

export default useServiceAuth
