import React from 'react'

const VisitorSignIn = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
      fill="currentColor"
    />
    <path
      d="M44 47H23C21.3431 47 20 45.6569 20 44"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 17C21.7909 17 20 18.7909 20 21V44C20 42.3431 21.3431 41 23 41H42C43.1046 41 44 40.1046 44 39V19C44 17.8954 43.1046 17 42 17H24Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M42 47V41" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 30C33.933 30 35.5 28.433 35.5 26.5C35.5 24.567 33.933 23 32 23C30.067 23 28.5 24.567 28.5 26.5C28.5 28.433 30.067 30 32 30Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26 37C26 33.6863 28.6863 31 32 31C35.3137 31 38 33.6863 38 37"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default VisitorSignIn
