import { useEffect, useState } from 'react'
import useFetch from 'use-http'

function useChildren(service: Xplor.Service) {
  const [children, setChildren] = useState<Xplor.Child[]>([])
  const [errors, setErrors] = useState<Nullable<string>>(null)

  const { request, loading } = useFetch()

  useEffect(() => {
    const fetchData = async () => {
      const response = await request.get(`/api/parent/bookings/${service.id}`)

      if (response && response.data) {
        setChildren(response.data)
      } else {
        setErrors(response.errors)
      }
    }

    fetchData()
  }, [service])

  return { children, errors, loading }
}

export default useChildren
