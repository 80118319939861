import React from 'react'

const Add = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.375 11.25H24.75V5.625C24.75 2.5184 22.2316 0 19.125 0H16.875C13.7684 0 11.25 2.5184 11.25 5.625V11.25H5.625C2.5184 11.25 0 13.7684 0 16.875V19.125C0 22.2316 2.5184 24.75 5.625 24.75H11.25V30.375C11.25 33.4816 13.7684 36 16.875 36H19.125C22.2316 36 24.75 33.4816 24.75 30.375V24.75H30.375C33.4816 24.75 36 22.2316 36 19.125V16.875C36 13.7684 33.4816 11.25 30.375 11.25ZM23.625 13.5H30.375C32.239 13.5 33.75 15.011 33.75 16.875V19.125C33.75 20.989 32.239 22.5 30.375 22.5H23.625C23.0037 22.5 22.5 23.0037 22.5 23.625V30.375C22.5 32.239 20.989 33.75 19.125 33.75H16.875C15.011 33.75 13.5 32.239 13.5 30.375V23.625C13.5 23.0037 12.9963 22.5 12.375 22.5H5.625C3.76104 22.5 2.25 20.989 2.25 19.125V16.875C2.25 15.011 3.76104 13.5 5.625 13.5H12.375C12.9963 13.5 13.5 12.9963 13.5 12.375V5.625C13.5 3.76104 15.011 2.25 16.875 2.25H19.125C20.989 2.25 22.5 3.76104 22.5 5.625V12.375C22.5 12.9963 23.0037 13.5 23.625 13.5Z"
      fill="white"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.375 11.25H24.75V5.625C24.75 2.5184 22.2316 0 19.125 0H16.875C13.7684 0 11.25 2.5184 11.25 5.625V11.25H5.625C2.5184 11.25 0 13.7684 0 16.875V19.125C0 22.2316 2.5184 24.75 5.625 24.75H11.25V30.375C11.25 33.4816 13.7684 36 16.875 36H19.125C22.2316 36 24.75 33.4816 24.75 30.375V24.75H30.375C33.4816 24.75 36 22.2316 36 19.125V16.875C36 13.7684 33.4816 11.25 30.375 11.25ZM23.625 13.5H30.375C32.239 13.5 33.75 15.011 33.75 16.875V19.125C33.75 20.989 32.239 22.5 30.375 22.5H23.625C23.0037 22.5 22.5 23.0037 22.5 23.625V30.375C22.5 32.239 20.989 33.75 19.125 33.75H16.875C15.011 33.75 13.5 32.239 13.5 30.375V23.625C13.5 23.0037 12.9963 22.5 12.375 22.5H5.625C3.76104 22.5 2.25 20.989 2.25 19.125V16.875C2.25 15.011 3.76104 13.5 5.625 13.5H12.375C12.9963 13.5 13.5 12.9963 13.5 12.375V5.625C13.5 3.76104 15.011 2.25 16.875 2.25H19.125C20.989 2.25 22.5 3.76104 22.5 5.625V12.375C22.5 12.9963 23.0037 13.5 23.625 13.5Z"
        fill="white"
      />
    </mask>
  </svg>
)

export default Add
