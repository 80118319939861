import React, { useEffect } from 'react'

import { Box, Card, Heading, Image, Stack, Text } from '@myxplor/stardust'

import * as Analytics from '~/helpers/analytics'

import babyImage from '~/assets/images/baby.png'

const NoChildren = () => {
  useEffect(() => {
    Analytics.parentNoChildren()
  }, [])

  return (
    <Stack align="center">
      <Box paddingY="large" maxWidth="600px" width={1}>
        <Card>
          <Box paddingX="xxlarge" paddingY="xlarge">
            <Stack align="center" space="large">
              <Heading align="center" level={6} lineHeight={1.4}>
                You do not have any children enrolled at this centre
              </Heading>
              <Image src={babyImage} />
              <Text align="center" color="highestEmphasis" prefab="bodyAlt">
                Please talk to the centre adminstrator for assistance.
              </Text>
            </Stack>
          </Box>
        </Card>
      </Box>
    </Stack>
  )
}

export default NoChildren
