import React from 'react'

const ShiftStart = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 46.9976C40.2843 46.9976 47 40.2818 47 31.9976C47 23.7133 40.2843 16.9976 32 16.9976C23.7157 16.9976 17 23.7133 17 31.9976C17 40.2818 23.7157 46.9976 32 46.9976Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 36.8176C28.0007 37.6728 28.501 38.4486 29.2797 38.8021C30.0584 39.1555 30.9718 39.0214 31.616 38.459L39 32.0003L31.616 25.5376C30.9722 24.974 30.0581 24.8391 29.279 25.1928C28.4998 25.5464 27.9996 26.3233 28 27.179V36.8176Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ShiftStart
