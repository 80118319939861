import React, { ReactNode, useState } from 'react'

import {
  Anchor,
  Alert,
  Box,
  Button,
  Form,
  Heading,
  Inline,
  Popup,
  Stack,
  Text,
  TextField,
} from '@myxplor/stardust'

import { isStringEmpty } from '~/helpers/utils'
import { AuthMode } from '~/pages/ParentEducatorLogin/common'

interface Props {
  authMode: AuthMode
  children: ReactNode
  isLoading: boolean
  onSubmit(email: string, resetType: 'email' | 'phone'): Promise<string | void>
  onSwitchAuthMode(authMode: string): void
}

type PageFlow = 'forgotPIN' | 'resetPIN' | 'resetEmailSent'

const ForgotLoginModal = ({ authMode, children, isLoading, onSubmit, onSwitchAuthMode }: Props) => {
  const [email, setEmail] = useState<string>('')
  const [errors, setErrors] = useState<Nullable<string>>(null)
  const [page, setPage] = useState<Nullable<PageFlow>>('forgotPIN')

  const attemptSubmit = async () => {
    if (isStringEmpty(email)) {
      return setErrors('Please enter your email')
    }

    setErrors(null)

    const error = await onSubmit(email, authMode === 'pin' ? 'phone' : 'email')
    if (!error) {
      setPage('resetEmailSent')
    } else {
      setErrors(error)
    }
  }

  const authModeString = () => (authMode === 'password' ? 'Password' : 'PIN')
  const altAuthModeString = () => (authMode === 'password' ? 'PIN' : 'Password')
  const authModeBtnString = () => (authMode === 'password' ? 'Reset Password' : 'Reset PIN')

  const forgotPIN = () => {
    return (
      <Stack space="medium">
        <Heading level={6}>Forgot {authModeString()}</Heading>
        <Text color="highestEmphasis" prefab="body">
          Your {authModeString()} is the same one that is used on the Xplor Home app.
        </Text>
        <Text color="highestEmphasis" prefab="body">
          Try using your {altAuthModeString()} instead?
        </Text>
        <Box width={1}>
          <Inline align="right" space="small">
            <Button disabled={isLoading} variant="outline" onClick={() => setPage('resetPIN')}>
              {authModeBtnString()}
            </Button>
            <Button
              disabled={isLoading}
              onClick={() => onSwitchAuthMode(authMode === 'password' ? 'pin' : 'password')}>
              Use {altAuthModeString()}
            </Button>
          </Inline>
        </Box>
      </Stack>
    )
  }

  const resetPIN = () => {
    return (
      <Form onSubmit={attemptSubmit}>
        <Stack space="medium">
          <Heading level={6}>Forgot {authModeString()}</Heading>
          <Text color="highestEmphasis" prefab="body">
            Enter your email address to reset your {authModeString()}.
          </Text>

          <TextField onChange={(val: string) => setEmail(val)} label="Email" />

          {errors && <Alert tone="negative">{errors}</Alert>}

          <Box width={1}>
            <Inline align="right" space="small">
              <Button disabled={isLoading} type="submit">
                Send Reset Email
              </Button>
            </Inline>
          </Box>
        </Stack>
      </Form>
    )
  }

  const resetEmailSent = () => {
    const onBack = () => setPage('resetPIN')

    return (
      <Stack space="medium">
        <Heading level={6}>Reset {authModeString()}</Heading>
        <Text color="highestEmphasis" prefab="body">
          Email sent to {email}. Press on the link in the email to reset your {authModeString()}.
        </Text>
        <Text color="highestEmphasis" prefab="body">
          Can&apos;t find it? Check your junk folder or&nbsp;
          <Anchor size="body" onClick={onBack}>
            resend email
          </Anchor>
        </Text>
      </Stack>
    )
  }

  const renderResetFlow = () => {
    switch (page) {
      case 'forgotPIN':
        return forgotPIN()
      case 'resetPIN':
        return resetPIN()
      case 'resetEmailSent':
        return resetEmailSent()
      default:
        return forgotPIN()
    }
  }

  return (
    <Popup
      activator={children}
      direction={['below', 'right']}
      showClose
      onOpen={() => {
        setPage('forgotPIN')
        setEmail('')
        setErrors(null)
      }}>
      <Box padding="large" width="440px">
        {renderResetFlow()}
      </Box>
    </Popup>
  )
}

export default React.memo(ForgotLoginModal)
