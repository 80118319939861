import React from 'react'

const ChevronDown = () => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.41 0.294956L-2.62268e-07 1.70496L6 7.70496L12 1.70496L10.59 0.294956L6 4.87496L1.41 0.294956Z"
      fill="#F28602"
    />
  </svg>
)

export default ChevronDown
