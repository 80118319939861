import React, { useEffect } from 'react'

import { Box, Card, Image, Heading, Stack, Text } from '@myxplor/stardust'

import * as Analytics from '~/helpers/analytics'

import babyImage from '~/assets/images/baby.png'

const NoSessions = () => {
  useEffect(() => {
    Analytics.parentNoCasualBookings()
  }, [])

  return (
    <Stack align="center">
      <Box paddingY="large" maxWidth="576px" width={1}>
        <Card>
          <Box paddingX="xxlarge" paddingY="xlarge">
            <Stack align="center" space="large">
              <Heading align="center" level={6}>
                Unable to find available session.
              </Heading>
              <Image src={babyImage} />
              <Text align="center" color="highestEmphasis" prefab="bodyAlt">
                All available rooms might be full or your centre has conflicting booking rules.
              </Text>
              <Text align="center" color="highestEmphasis" prefab="bodyAlt">
                Please talk with your centre adminstrator to sign in another way.
              </Text>
            </Stack>
          </Box>
        </Card>
      </Box>
    </Stack>
  )
}

export default React.memo(NoSessions)
