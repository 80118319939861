/* global process */

// Top-level configuration for the App. Takes most variables from ENV files,
// sourced via dotenv

const DEBUG = process.env.NODE_ENV === 'development'
const HUB_API = process.env.HUB_API
const GOOGLE_ANALYTICS_PROPERTY = process.env.GOOGLE_ANALYTICS_PROPERTY
const VERSION = process.env.VERSION

const config = {
  DEBUG,
  HUB_API,
  GOOGLE_ANALYTICS_PROPERTY,
  VERSION,
}

Object.entries(config).forEach(([_key, value]: any) => {
  if (value === null || value === undefined) {
    throw Error(
      `App not configured correctly - missing environment variables.
        If running locally: Have you configured your .env?`
    )
  }
})

export default config
