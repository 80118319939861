import React, { useEffect } from 'react'

import { Box, Card, Heading, Stack, Text } from '@myxplor/stardust'

import * as Analytics from '~/helpers/analytics'

import { Content } from '~/layouts/FixedHeaderLayout'

import ContactDetailsImage from '~/icons/ContactDetails'

const NoEmployeeShifts = () => {
  useEffect(() => {
    Analytics.educatorNoShift()
  }, [])

  return (
    <Content withFooter withHeader>
      <Box paddingX="xxxlarge" paddingY="large">
        <Card>
          <Box paddingBottom="xxlarge" paddingTop="xlarge" paddingX="large">
            <Stack space="xxlarge" align="center">
              <Heading level={5} weight="bold">
                {'No shifts to begin'}
              </Heading>
              <ContactDetailsImage />
              <Text align="center" color="highestEmphasis" prefab="bodyAlt">
                To start a shift, make sure your centre administrator has one configured in Xplor rostering
              </Text>
            </Stack>
          </Box>
        </Card>
      </Box>
    </Content>
  )
}

export default React.memo(NoEmployeeShifts)
