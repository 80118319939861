import React from 'react'

import { Animation, Box, Card, Heading, Modal, Stack } from '@myxplor/stardust'
import tickAnimation from '@myxplor/stardust/dist/animations/tick.json'

interface Props {
  isOpen: boolean
}
const SignOutConfirmation = ({ isOpen }: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <Card>
        <Box padding="large" maxWidth="350px">
          <Stack align="center" space="small">
            <Box height="154px" width="200px">
              {isOpen && <Animation data={tickAnimation} />}
            </Box>

            <Heading align="center" color="secondary" level={6} lineHeight={1.4} weight="bold">
              Remember to Sign Out when you leave
            </Heading>
          </Stack>
        </Box>
      </Card>
    </Modal>
  )
}

export default React.memo(SignOutConfirmation)
