import React from 'react'

import { Box, Heading, Image, Inline, Popup, Stack, Text } from '@myxplor/stardust'

import QRCode from '~/pages/ServiceDashboard/QRCode'

import iosStore from '~/assets/images/iosStore.png'
import globalPlaystore from '~/assets/images/globalPlaystore.png'

interface AppStoreModalProps {
  serviceName: string
  token: Nullable<string>
}

const AppStoreModal = ({ token, serviceName }: AppStoreModalProps) => {
  return (
    <Popup
      direction={['below', 'below', 'right']}
      activator={
        <Stack align="center">
          <QRCode token={token} />
        </Stack>
      }>
      <Box padding="medium" width="495px">
        <Stack space="medium">
          <Heading level={6}>Sign in faster with the Xplor Home App</Heading>
          <Text prefab="body">
            {`See the photos and videos of your child’s learning, monitor health and make bookings at
            ${serviceName}.`}
          </Text>
          <Inline space="medium">
            <Image src={iosStore} />
            <Image src={globalPlaystore} />
          </Inline>
        </Stack>
      </Box>
    </Popup>
  )
}

export default React.memo(AppStoreModal)
