import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Box } from '@myxplor/stardust'

const headerHeight = '120px'
const maxWidth = '960px'
const padding = '32px'
const footerHeight = '84px'

const Container = ({ children }: { children: ReactNode }) => (
  <Box display="flex" flexDirection="column">
    {children}
  </Box>
)

const Header = ({ children }: { children: ReactNode }) => (
  <Box
    backdropFilter="blur(54px)"
    backgroundColor="highBlend"
    borderBottom="1px solid transparent"
    borderColor="lowerEmphasis"
    display="flex"
    position="fixed"
    top={0}
    width={1}
    zIndex={10}>
    <Box
      display="flex"
      justifyContent="space-between"
      margin="0 auto"
      maxWidth={maxWidth}
      paddingX="large"
      paddingY="medium"
      width={1}>
      {children}
    </Box>
  </Box>
)

const Footer = ({ children }: { children: ReactNode }) => (
  <Box
    backdropFilter="blur(54px)"
    backgroundColor="highBlend"
    borderColor="lowerEmphasis"
    borderTop="1px solid transparent"
    bottom={0}
    display="flex"
    position="fixed"
    width={1}
    zIndex={10}>
    <Box
      display="flex"
      justifyContent="space-between"
      margin="0 auto"
      maxWidth={maxWidth}
      paddingX="large"
      paddingY="medium"
      width={1}>
      {children}
    </Box>
  </Box>
)

interface ContentProps {
  withFooter?: boolean
  withHeader?: boolean
  paddingTop?: string
}

const Content = styled(Box)`
  padding: ${padding};
  overflow: visible;
  margin: ${(props: ContentProps) => (props.withHeader ? headerHeight : 0)} auto 0;
  max-width: ${maxWidth};
  width: 100%;
  padding-bottom: ${(props: ContentProps) => (props.withFooter ? `calc(${footerHeight} + 16px)` : undefined)};
  padding-top: ${(props: ContentProps) => (props.paddingTop ? props.paddingTop : padding)};

  @media (min-width: 500px) {
    padding: ${padding};
    padding-bottom: ${(props: ContentProps) =>
      props.withFooter ? `calc(${footerHeight} + ${padding})` : undefined};
    padding-top: ${(props: ContentProps) => (props.paddingTop ? props.paddingTop : padding)};
  }
`

export { Container, Header, Content, Footer }
