import React from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Box, Button, Card, Image, PageHeading, Stack, Text, useTheme } from '@myxplor/stardust'

import * as Analytics from '~/helpers/analytics'
import ufo from '~/assets/images/ufo.svg'
import ufoDark from '~/assets/images/ufo_dark.svg'

import { Container, Header, Content } from '~/layouts/FixedHeaderLayout'

interface Props extends RouteComponentProps {
  messages: Xplor.Message[]
  setResetSessionTimerRequired(resetSessionTimerRequired: boolean): void
  roles: Xplor.Role[]
  service: Xplor.Service
}

const Messages = ({ messages, setResetSessionTimerRequired, roles, service }: Props) => {
  const { request } = useFetch()
  const { name: themeName } = useTheme()
  const ufoIcon = themeName === 'dark' ? ufoDark : ufo

  const getCurrentMessage = () => {
    return messages.length > 0 ? messages[0] : undefined
  }

  const getCurrentMessageText = () => {
    const currentMessage = getCurrentMessage()
    return currentMessage ? currentMessage.body : ''
  }

  const onMessageRead = async (message?: Xplor.Message) => {
    if (message) {
      const params = { message_id: message.id }
      await request.post(`/api/parent/messages/${service.id}/mark_as_read`, params)

      Analytics.parentReadMessage()
      navigate('/attendance')
      setResetSessionTimerRequired(true)
    }
  }

  return (
    <Container>
      <Header>
        <PageHeading
          linkColor="primary"
          overline={roles.length > 1 ? 'Choose role' : 'Dashboard'}
          onBack={() => navigate(roles.length > 1 ? '/role-selection' : '/service')}>
          Hub Message
        </PageHeading>
      </Header>

      <Content withHeader>
        <Box padding="medium">
          <Card>
            <Box paddingTop="xxlarge" height="530px">
              <Stack align="center" space="large">
                <Box>
                  <Image src={ufoIcon} />
                </Box>
                <Box display="flex" alignItems="center" paddingLeft="xlarge" paddingRight="xlarge">
                  <Text wordBreak="break-word" size="h4" color="highestEmphasis" align="center">
                    {getCurrentMessageText()}
                  </Text>
                </Box>
              </Stack>
            </Box>
            <Box display="flex" justifyContent="center" position="absolute" bottom={-24} right={30}>
              <Box width="170px">
                <Button
                  color="orange900"
                  onClick={() => onMessageRead(getCurrentMessage())}
                  fill
                  size="large">
                  {"I've read this"}
                </Button>
              </Box>
            </Box>
          </Card>
        </Box>
      </Content>
    </Container>
  )
}

export default React.memo(Messages)
