import React from 'react'
import { Heading, Text, Stack } from '@myxplor/stardust'
import { getLocalDateTime, formatLocalDate, formatLocalTime } from '~/helpers/dates'

const Clock = () => {
  const dateLocal = formatLocalDate(getLocalDateTime())
  const timeLocal = formatLocalTime(getLocalDateTime())

  return (
    <Stack align="center">
      <Text prefab="subtitleAlt">{dateLocal}</Text>
      <Heading level={3}>{timeLocal}</Heading>
    </Stack>
  )
}

export default React.memo(Clock)
