import React from 'react'

const User = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM5.05547 19.8757C2.87517 17.9517 1.5 15.1364 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 15.1364 21.1248 17.9517 18.9445 19.8757C17.033 18.1888 14.5651 17.2499 12 17.2499C9.4349 17.2499 6.96703 18.1888 5.05547 19.8757ZM6.27718 20.8048C7.9234 21.877 9.88894 22.5 12 22.5C14.1111 22.5 16.0766 21.877 17.7228 20.8048C16.1188 19.4822 14.0973 18.7499 12 18.7499C9.90269 18.7499 7.88122 19.4822 6.27718 20.8048ZM18 9.75C18 13.0637 15.3137 15.75 12 15.75C8.68629 15.75 6 13.0637 6 9.75C6 6.43629 8.68629 3.75 12 3.75C15.3137 3.75 18 6.43629 18 9.75ZM7.5 9.75C7.5 12.2353 9.51472 14.25 12 14.25C14.4853 14.25 16.5 12.2353 16.5 9.75C16.5 7.26472 14.4853 5.25 12 5.25C9.51472 5.25 7.5 7.26472 7.5 9.75Z"
      fill="currentColor"
      fillOpacity="0.6"
    />
  </svg>
)

export default User
