import React, { useEffect, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Column, Columns, DatePicker, Heading, Loading, PageHeading, Stack, Text } from '@myxplor/stardust'

import * as Analytics from '~/helpers/analytics'

import {
  getDayBoundaries,
  getHour,
  getLocalDateTime,
  formatLocalDate,
  formatLocalTime,
  toISODate,
} from '~/helpers/dates'

import { createLookup, objectValues, sortObjectArray } from '~/helpers/utils'

import { Container, Header, Content } from '~/layouts/FixedHeaderLayout'

import ChevronDownIcon from '~/icons/ChevronDown'

import SignInButton from './SignInButton'
import SignOutConfirmation from './SignOutConfirmation'
import VisitorCard from './VisitorCard'

type Props = RouteComponentProps

const VisitorLog = (_props: Props) => {
  const [date, setDate] = useState(toISODate(getLocalDateTime()))
  const [loading, setLoading] = useState(true)
  const [signOutVisitor, setSignOutVisitor] = useState<Nullable<Xplor.Visitor>>()
  const [visitors, setVisitors] = useState<Record<number, Xplor.Visitor>>({})

  const { request } = useFetch()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      const dateLocal = getLocalDateTime(date)
      const [startOfDay, endOfDay] = getDayBoundaries(dateLocal)
      const path = `/api/service/visitors?from=${startOfDay}&to=${endOfDay}`
      const response = await request.get(path)

      setVisitors(createLookup<Xplor.Visitor>(response.data))
      setLoading(false)
    }

    fetchData()
  }, [date])

  const visitorArray = objectValues(visitors) as Xplor.Visitor[]
  const sortedVisitors = sortObjectArray(visitorArray, ['signed_in']).reverse()
  const morningVisitors = sortedVisitors.filter(v => getHour(v.signed_in) < 12)
  const afternoonVisitors = sortedVisitors.filter(v => getHour(v.signed_in) >= 12)

  const onSignOut = async (visitor: Xplor.Visitor) => {
    await request.patch(`/api/service/visitors/${visitor.id}`)

    Analytics.visitorLogOut()

    navigate('/service')
  }

  const renderVisitors = (label: string, items: Xplor.Visitor[]) =>
    items.length == 0 ? null : (
      <Stack space="small">
        <Text color="highestEmphasis" prefab="overline">
          {label}
        </Text>
        <Stack space="small">
          {items.map(visitor => (
            <VisitorCard key={visitor.id} visitor={visitor} onSignOut={() => setSignOutVisitor(visitor)} />
          ))}
        </Stack>
      </Stack>
    )

  return (
    <Container>
      <Header>
        <Columns alignY="bottom">
          <Column width={2 / 3}>
            <PageHeading linkColor="primary" overline="Dashboard" onBack={() => navigate('/service')}>
              Visitor Log
            </PageHeading>
          </Column>
          <Column alignY="bottom" width={1 / 3}>
            <Stack align="right" space="xxxsmall">
              <DatePicker
                activator={
                  <Text prefab="button" whiteSpace="nowrap">
                    {`${formatLocalDate(date)}  `}
                    <ChevronDownIcon />
                  </Text>
                }
                align="right"
                value={date}
                onChange={setDate}
              />

              <Heading level={4}>{formatLocalTime(getLocalDateTime())}</Heading>
            </Stack>
          </Column>
        </Columns>
      </Header>

      <Content withHeader>
        {loading ? (
          <Loading size="large" />
        ) : (
          <Stack space="medium">
            <SignInButton onClick={() => navigate('/visitors/new')} />

            {renderVisitors('Afternoon', afternoonVisitors)}
            {renderVisitors('Morning', morningVisitors)}
          </Stack>
        )}
      </Content>

      <SignOutConfirmation
        visitor={signOutVisitor}
        onCancel={() => setSignOutVisitor(null)}
        onConfirm={onSignOut}
      />
    </Container>
  )
}

export default React.memo(VisitorLog)
