import React, { useEffect, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import {
  Alert,
  Anchor,
  Box,
  Button,
  Form,
  Image,
  Inline,
  PageHeading,
  Stack,
  Text,
  TextField,
} from '@myxplor/stardust'

import DialogLayout from '~/layouts/DialogLayout'

import UserIcon from '~/icons/User'
import VisibilityOff from '~/icons/VisibilityOff'
import VisibilityOn from '~/icons/VisibilityOn'
import ForgotPasswordModal from './ForgotPasswordModal'

import xplorRocketImage from '~/assets/images/xplorange.svg'

interface Props extends RouteComponentProps {
  service: Nullable<Xplor.Service>
  onServiceAuth(token: string): void
}

const ServiceLogin = ({ service, onServiceAuth }: Props) => {
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [errors, setErrors] = useState<Nullable<string>>(null)
  const { request, loading } = useFetch()

  // Redirect to the dashboard if already logged in
  useEffect(() => {
    if (service) navigate('/service')
  }, [service])

  const onSubmit = async () => {
    setErrors(null)

    try {
      const params = { identifier: identifier.trim(), password }
      const response = await request.post('/api/auth/service', params)

      if (response.errors) {
        setErrors(response.errors)
      } else if (response && response.data) {
        onServiceAuth(response.data.token)
      }
    } catch (error) {
      console.error(error)
      setErrors('Unable to communicate with server. Please contact support')
    }
  }

  const onSubmitPasswordReset = async (email: string) => {
    try {
      const response = await request.post('/api/auth/password-reset', {
        identifier: email,
        strategies: ['ServiceAdmin'],
      })

      if (response.errors) {
        return setErrors(response.errors)
      }
    } catch (error) {
      console.error(error)
      return 'Unable to communicate with server. Please contact support'
    }
  }

  const onTogglePasswordMask = () => setShowPassword(!showPassword)

  return (
    <DialogLayout>
      <PageHeading level={4} linkColor="primary" overline="Xplor">
        Centre Login
      </PageHeading>

      <Stack align="center" space="large">
        <Box paddingBottom="large" paddingTop="xxlarge">
          <Box width={72}>
            <Image src={xplorRocketImage} />
          </Box>
        </Box>

        <Form onSubmit={onSubmit}>
          <Stack align="center" space="large">
            <Stack space="small">
              <TextField
                label="Username"
                name="identifier"
                value={identifier}
                type="text"
                onChange={(value: string) => setIdentifier(value)}
                trailingIcon={
                  <Box color="mediumEmphasis">
                    <UserIcon />
                  </Box>
                }
              />
              <TextField
                label="Password"
                name="password"
                value={password}
                type={showPassword ? 'text' : 'password'}
                onChange={(value: string) => setPassword(value)}
                trailingIcon={
                  <Box color="mediumEmphasis" onClick={onTogglePasswordMask}>
                    {showPassword ? <VisibilityOn /> : <VisibilityOff />}
                  </Box>
                }
              />

              {errors && <Alert tone="negative">{errors}</Alert>}
            </Stack>

            <Button disabled={loading} fill size="large" type="submit">
              Login
            </Button>
          </Stack>
        </Form>

        <Inline align="center">
          <ForgotPasswordModal isLoading={loading} onSubmit={onSubmitPasswordReset}>
            <Anchor>Forgot password?</Anchor>
          </ForgotPasswordModal>
        </Inline>

        <Text color="highestEmphasis" prefab="caption">
          Your centre Username is also used to login to Playground and can be found under Service Settings on
          Xplor Office.
        </Text>
      </Stack>
    </DialogLayout>
  )
}

export default React.memo(ServiceLogin)
