import React from 'react'

import { Box, Card, Column, Columns, Heading, Stack, Text } from '@myxplor/stardust'

import AddIcon from '~/icons/Add'

interface Props {
  onClick(): void
}

const SignInButton = ({ onClick }: Props) => (
  <Card onClick={onClick}>
    <Box paddingX="medium" paddingY="large">
      <Columns alignY="center" space="medium">
        <Column>
          <Box
            alignItems="center"
            backgroundColor="primary"
            border="1px solid"
            borderColor="primary"
            borderRadius="circular"
            display="flex"
            height="72px"
            justifyContent="center"
            width="72px">
            <AddIcon />
          </Box>
        </Column>

        <Column width="fill">
          <Stack space="xxsmall">
            <Heading color="primary" level={5} weight="bold">
              Sign in
            </Heading>
            <Text color="highEmphasis" prefab="bodyAlt" weight="medium">
              New Visitor
            </Text>
          </Stack>
        </Column>
      </Columns>
    </Box>
  </Card>
)

export default React.memo(SignInButton)
