import React from 'react'
import QRCodeReact from 'qrcode.react'

import { Box, Stack, Text, useColor } from '@myxplor/stardust'

interface Props {
  token: Nullable<string>
}
const QRCode = ({ token }: Props) => {
  const color = useColor('primary')
  return token ? (
    <Box
      backgroundColor="primary"
      padding="small"
      paddingBottom="xxsmall"
      style={{ borderRadius: '24px' }}
      textAlign="center">
      <Stack align="center" space="xxsmall">
        <Box backgroundColor="shade50" borderRadius="small" padding="small">
          <QRCodeReact
            value={token}
            level="M"
            size={166}
            renderAs="svg"
            includeMargin={false}
            fgColor={color}
          />
        </Box>
        <Text color="shade50" lineHeight="26px" prefab="body" weight="bold">
          Xplor Home
        </Text>
      </Stack>
    </Box>
  ) : (
    <Box backgroundColor="lowestEmphasis" borderRadius="medium" height="256px" width="230px" />
  )
}

export default React.memo(QRCode)
