import React from 'react'

import { Box, Card, Color, Column, Columns, Heading, Stack, Text } from '@myxplor/stardust'
import {
  formatTime,
  isLaterDate,
  isWithinRange,
  getLocalDateTime,
  getTimeInterval,
  parseSQLTime,
} from '~/helpers/dates'
import ShiftStartIcon from '~/icons/ShiftStart'
import ShiftBreakIcon from '~/icons/ShiftBreak'
import ShiftEndIcon from '~/icons/ShiftEnd'

interface Props {
  action: string
  isDisabled: boolean
  shift: Xplor.Shift
  onShiftAction(action: string): void
}

const ShiftAction = ({ action, shift, isDisabled, onShiftAction }: Props) => {
  const nextBreak = () => {
    const withinBreakTime = shift.breaks.filter(x =>
      isWithinRange(getLocalDateTime(), x.start_time, x.finish_time)
    )
    const upcomingBreakTimes = shift.breaks
      .filter(x => isLaterDate(x.start_time, getLocalDateTime()))
      .sort((a, b) =>
        getTimeInterval(getLocalDateTime(), a.start_time) < getTimeInterval(getLocalDateTime(), b.start_time)
          ? -1
          : 1
      )

    return withinBreakTime.length > 0 ? withinBreakTime : upcomingBreakTimes
  }

  const getDescription = () => {
    switch (action) {
      case 'start-shift':
      case 'finish-shift':
        return `${shift.room}`
      case 'start-break-enabled':
      case 'start-break-disabled':
        if (nextBreak().length > 0) {
          return `${formatTime(nextBreak()[0].start_time)} - ${formatTime(nextBreak()[0].finish_time)}`
        } else {
          return 'No breaks remaining'
        }
      case 'finish-break': {
        const breaks = nextBreak()

        const getActiveBreak = (shift: Xplor.Shift) => {
          const activeBreaks = shift.attendance.break.filter(x => x.status === 'CHECKED_IN')
          return activeBreaks[0]
        }

        if (getActiveBreak(shift)) {
          const breakDuration =
            breaks.length > 0
              ? getTimeInterval(breaks[0].start_time, breaks[0].finish_time).length('minutes')
              : getTimeInterval(getActiveBreak(shift).start_time, shift.finish_time).length('minutes')

          const expectedFinishTime = parseSQLTime(getActiveBreak(shift).start_time).plus({
            minutes: breakDuration,
          })

          const currentTime = getLocalDateTime()
          const finishTime = expectedFinishTime

          const getTimeRemaining = () => {
            const interval = getTimeInterval(currentTime, finishTime)
            return interval.isValid
              ? interval.toDuration(['hours', 'minutes']).toObject()
              : { hours: 0, minutes: 0 }
          }

          const timeRemaining = getTimeRemaining()
          const hours = timeRemaining.hours ? `${timeRemaining.hours}h` : ''
          const minutes = timeRemaining.minutes ? ` ${Math.ceil(timeRemaining.minutes)}m` : '0m'
          if (breaks.length > 0) {
            return `${hours} ${minutes} remaining`
          } else {
            return `${hours} ${minutes} till end of shift`
          }
        } else {
          return 'No active break'
        }
      }
      default:
        return null
    }
  }

  const getLabel = () => {
    switch (action) {
      case 'start-shift':
        return 'Start Shift'
      case 'finish-shift':
        return 'End Shift'
      case 'start-break-enabled':
      case 'start-break-disabled':
        return 'Start Break'
      case 'finish-break':
        return 'Resume Shift'
      default:
        return null
    }
  }

  const getIcon = () => {
    switch (action) {
      case 'start-shift':
      case 'finish-break':
        return <ShiftStartIcon />
      case 'finish-shift':
        return <ShiftEndIcon />
      case 'start-break-enabled':
      case 'start-break-disabled':
        return <ShiftBreakIcon />
      default:
        return null
    }
  }

  const getColor = (): Color => {
    switch (action) {
      case 'start-shift':
      case 'start-break-enabled':
      case 'finish-break':
        return 'secondary'
      case 'finish-shift':
        return 'negative'
      case 'start-break-disabled':
        return 'lowEmphasis'
      default:
        return 'secondary'
    }
  }

  const disabled = isDisabled || action === 'start-break-disabled'
  const color = disabled ? 'lowEmphasis' : getColor()
  const description = getDescription()
  const headingLabel = getLabel()
  const icon = getIcon()
  const onClick = () => (disabled ? null : onShiftAction(action))

  return (
    <Card elevation="12dp" onClick={onClick}>
      <Box paddingX="large" paddingY="medium">
        <Columns alignY="center" space="large">
          <Column width="fill">
            <Stack space="xxsmall">
              <Heading color={color} level={5} weight="bold">
                {headingLabel}
              </Heading>
              <Text lineHeight={1} prefab="subtitleAlt" weight="medium">
                {description}
              </Text>
            </Stack>
          </Column>
          <Column>
            <Box color={color} height={64} width={64}>
              {icon}
            </Box>
          </Column>
        </Columns>
      </Box>
    </Card>
  )
}

export default React.memo(ShiftAction)
