import React from 'react'

const ContextSignIn = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
      fill="currentColor"
    />
    <path
      d="M29 47.0053H21C19.8954 47.0053 19 46.1099 19 45.0053V19.0053C19 17.9007 19.8954 17.0053 21 17.0053H35C36.1046 17.0053 37 17.9007 37 19.0053V26"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.5 31.5053C23.2973 31.5053 23.1146 31.6278 23.0376 31.8152C22.9605 32.0027 23.0043 32.2182 23.1484 32.3608C23.2925 32.5033 23.5084 32.5448 23.6951 32.4657C23.8817 32.3867 24.0021 32.2027 24 32C24 31.7239 23.7761 31.5 23.5 31.5V31.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33 39.0053C33 37.9007 33.8954 37.0053 35 37.0053H43C44.1046 37.0053 45 37.9007 45 39.0053V45.0053C45 46.1099 44.1046 47.0053 43 47.0053H35C33.8954 47.0053 33 46.1099 33 45.0053V39.0053Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35 37.0053V35.0053C35 32.7962 36.7909 31.0053 39 31.0053V31.0053C41.2091 31.0053 43 32.7962 43 35.0053V37.0053"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39 41.5053C38.7239 41.5053 38.5 41.7292 38.5 42.0053C38.5 42.2815 38.7239 42.5053 39 42.5053C39.2761 42.5053 39.5 42.2815 39.5 42.0053C39.5 41.7292 39.2761 41.5053 39 41.5053V41.5053"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ContextSignIn
