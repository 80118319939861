import { DateTime, Interval } from 'luxon'

type DateTimeProxy = DateTime | string

export function getTimeRemaining(start: DateTimeProxy, finish: DateTimeProxy) {
  const interval = getTimeInterval(start, finish)
  return interval.isValid ? Math.ceil(interval.length('minutes')) : 0
}

// Returns the start and end of the day in local time
export function getDayBoundaries(dt: DateTimeProxy) {
  const castDt = parseISODateTime(dt)
  const startOfDay = castDt.startOf('day')
  const endOfDay = castDt.endOf('day')
  const format = (dt: DateTime) => dt.setZone('UTC').toISO()
  return [format(startOfDay), format(endOfDay)]
}

export function getTimeInterval(start: DateTimeProxy, end: DateTimeProxy) {
  const startDate = parseSQLTime(start)
  const endDate = parseSQLTime(end)
  return Interval.fromDateTimes(startDate, endDate)
}

export function getTimeIntervalPercentage(
  start: DateTimeProxy,
  finish: DateTimeProxy,
  shiftLength: Interval
) {
  const startDate = parseSQLTime(start)
  const endDate = parseSQLTime(finish)
  const timeInterval = getTimeInterval(startDate, endDate)

  return Math.min(Math.floor((timeInterval.length('minutes') / shiftLength.length('minutes')) * 100), 100)
}

export function formatLocalDate(dt: DateTimeProxy) {
  return parseISODateTime(dt)
    .toLocal()
    .toFormat('cccc, d MMM')
}

export function formatLocalTime(dt: DateTimeProxy) {
  return parseISODateTime(dt)
    .toLocal()
    .toFormat('h:mm a')
}

export function formatTime(dt: DateTimeProxy) {
  return parseISODateTime(dt).toFormat('h:mm a')
}

export function getHour(dt: DateTimeProxy) {
  return parseISODateTime(dt).toLocal().hour
}

export function getLocalDateTime(dt?: DateTimeProxy) {
  return dt ? parseISODateTime(dt).toLocal() : DateTime.local()
}

export function getUTCDateTime(dt: DateTimeProxy) {
  return parseISODateTime(dt).toUTC()
}

// toISODate(new Date())
// > '2019-07-05'
export function toISODate(date: DateTimeProxy) {
  return parseISODateTime(date).toISODate()
}

// Converts date into a JS Date object
export function toJSDate(date: DateTimeProxy) {
  return parseISODateTime(date).toJSDate()
}

export function isLaterDate(start: DateTimeProxy, end: DateTimeProxy): boolean {
  const startDate = parseSQLTime(start)
  const endDate = parseSQLTime(end)

  return startDate > endDate ? true : false
}

export function isWithinRange(dt: DateTimeProxy, start: DateTimeProxy, end: DateTimeProxy): boolean {
  const startDate = parseSQLTime(start)
  const endDate = parseSQLTime(end)
  const date = parseSQLTime(dt)

  return date >= startDate && date <= endDate ? true : false
}

// Parses an ISO date string into a Luxon date object, or return
// the existing object is already in Luxon format
// Format: "2020-01-29T10:49:56.089548Z"
function parseISODateTime(dt: DateTimeProxy): DateTime {
  return typeof dt === 'string' ? DateTime.fromISO(dt, { zone: 'UTC' }) : dt
}

export function parseSQLTime(dt: DateTimeProxy): DateTime {
  return typeof dt === 'string' ? DateTime.fromSQL(dt) : dt
}
