import React, { useState, useEffect } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import useFetch from 'use-http'

import { Button, Column, Columns, Heading, Loading, PageHeading, Stack, Text, Tiles } from '@myxplor/stardust'

import * as Analytics from '~/helpers/analytics'
import { State } from '~/pages/ChildAttendance/common'
import { Container, Header, Content, Footer } from '~/layouts/FixedHeaderLayout'

import ErrorDialog from '~/components/ErrorDialog'

import ChildCard from './ChildCard'
import NoChildren from './NoChildren'
import useChildren from './useChildren'

const SIGN_OUT_ERROR_MESSAGE =
  'Something went wrong during sign out. Please try again, and if the problem persists talk to your centre administrator.'

interface Props extends RouteComponentProps {
  activeRole: Xplor.Role
  onResetSessionTimeout(): void
  resetSessionTimerRequired: boolean
  roles: Xplor.Role[]
  service: Xplor.Service
}

interface InitialState {
  state: State
  id: string
}

const ChildAttendance = ({
  activeRole,
  roles,
  resetSessionTimerRequired,
  onResetSessionTimeout,
  service,
}: Props) => {
  const { children, errors, loading } = useChildren(service)
  const [signInOutError, setSignInOutError] = useState<Nullable<string>>(null)
  const [stateOverride, setStateOverride] = useState<Nullable<InitialState>>(null)

  useEffect(() => {
    if (resetSessionTimerRequired) {
      onResetSessionTimeout()
    }
  }, [resetSessionTimerRequired])

  const { request } = useFetch()

  const checkOriginSource = () => {
    if (window.location.search.split('?').length > 1) {
      const attendanceParam = window.location.search.substring(1).split('=')
      if (attendanceParam.length > 0 && attendanceParam[0] === State.ActionSignInCasual) {
        setStateOverride({
          state: State.ActionSignInCasual,
          id: attendanceParam[1],
        })
      }
    }
  }

  const onSignIn = async (booking: Xplor.Booking) => {
    const params = { booking_id: booking.id }
    const response = await request.patch(`/api/parent/bookings/${service.id}/sign_in`, params)

    if (response !== undefined && response.errors) {
      setSignInOutError(response.errors)
    } else {
      Analytics.parentBookingSignIn()
    }
  }

  const onSignOut = async (booking: Xplor.Booking) => {
    const params = { booking_id: booking.id }
    const response = await request.patch(`/api/parent/bookings/${service.id}/sign_out`, params)

    if (response === undefined || response.errors) {
      setSignInOutError(SIGN_OUT_ERROR_MESSAGE)
    } else {
      Analytics.parentBookingSignOut()
    }
  }

  const getStateOverride = (id: number): State | null => {
    return stateOverride && stateOverride.id === String(id) ? stateOverride.state : null
  }

  const childrenWithBookings = children.filter(x => x.bookings.length > 0)
  const childrenWithoutBookings = children.filter(x => x.bookings.length === 0)
  const userName = `${activeRole.first_name} ${activeRole.last_name}`

  useEffect(() => checkOriginSource(), [])

  return (
    <Container>
      <Header>
        <PageHeading
          linkColor="primary"
          overline={roles.length > 1 ? 'Choose role' : 'Dashboard'}
          onBack={() => navigate(roles.length > 1 ? '/role-selection' : '/service')}>
          Attendance
        </PageHeading>
      </Header>

      <Content withHeader withFooter>
        {loading ? (
          <Loading size="large" />
        ) : (
          <Stack space="xlarge">
            {errors && <NoChildren />}

            {childrenWithBookings.length > 0 && (
              <Stack space="medium">
                <Text color="highestEmphasis" prefab="overline">
                  Bookings today
                </Text>
                <Tiles columns={[1, 1, 2]} space="large">
                  {childrenWithBookings.map(child =>
                    child.bookings.map(booking => (
                      <ChildCard
                        key={booking.id}
                        booking={booking}
                        child={child}
                        onSignIn={onSignIn}
                        onSignOut={onSignOut}
                        initialState={getStateOverride(child.id)}
                      />
                    ))
                  )}
                </Tiles>
              </Stack>
            )}

            {childrenWithoutBookings.length > 0 && (
              <Stack space="medium">
                <Text color="highestEmphasis" prefab="overline">
                  No bookings
                </Text>
                <Tiles columns={[1, 1, 2]} space="large">
                  {childrenWithoutBookings.map(child => (
                    <ChildCard
                      key={child.id}
                      child={child}
                      onSignIn={onSignIn}
                      onSignOut={onSignOut}
                      initialState={getStateOverride(child.id)}
                    />
                  ))}
                </Tiles>
              </Stack>
            )}
          </Stack>
        )}

        <ErrorDialog message={signInOutError} onConfirm={() => navigate('/service')} />
      </Content>

      <Footer>
        <Columns alignY="center" space="small">
          <Column width="fill">
            <Heading level={6} weight="regular">
              {userName}
            </Heading>
          </Column>
          <Column>
            <Button type="submit" onClick={() => navigate('/service')}>
              Log Out
            </Button>
          </Column>
        </Columns>
      </Footer>
    </Container>
  )
}

export default React.memo(ChildAttendance)
