import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _sortBy from 'lodash/sortBy'
import _uniqBy from 'lodash/uniqBy'
import _values from 'lodash/values'
import _startCase from 'lodash/startCase'

export function createLookup<T>(collection: T[], key = 'id'): Record<number, T> {
  return collection.reduce((acc: any, x: any) => {
    acc[x[key]] = x
    return acc
  }, {})
}

export function fullName(firstName: Nullable<string>, lastName: Nullable<string>) {
  return [firstName, lastName].filter((name?: Nullable<string>) => !_isEmpty(name)).join(' ')
}

export function startCase(str: string) {
  return _startCase(str)
}

export function isObjectEmpty(obj: object) {
  return _isEmpty(obj)
}

export function isStringEmpty(str: string) {
  return _isEmpty(str)
}

export function objectValues(obj: object) {
  return _values(obj)
}

export function sortObjectArray<T>(array: T[], keys: string[]): T[] {
  return _sortBy(array, keys) as T[]
}

export function uniqueBy<T>(objs: T[], func: (obj: T) => any) {
  return _uniqBy(objs, func)
}

export function getNestedProp(obj: object, path: string) {
  return _get(obj, path)
}
