import React, { useEffect, useRef, useState } from 'react'
import { Router, navigate } from '@reach/router'

import { DarkTheme, LightTheme, StardustProvider } from '@myxplor/stardust'

import * as Auth from '~/helpers/auth'

import useLocalStorage, { StorageKey } from '~/hooks/useLocalStorage'
import useRoleAuth from '~/hooks/useRoleAuth'
import useSessionAuth from '~/hooks/useServiceAuth'

import CasualSessions from '~/pages/CasualSessions'
import ChildAttendance from '~/pages/ChildAttendance'
import EmployeeShift from '~/pages/EmployeeShift'
import Messages from '~/pages/Messages'
import ParentEducatorLogin from '~/pages/ParentEducatorLogin'
import RoleSelection from '~/pages/RoleSelection'
import ServiceDashboard from '~/pages/ServiceDashboard'
import ServiceLogin from '~/pages/ServiceLogin'
import VisitorForm from '~/pages/VisitorForm'
import VisitorLog from '~/pages/VisitorLog'

import SessionTimer from '~/components/SessionTimer'

const themes = {
  dark: DarkTheme,
  light: LightTheme,
}

const App = () => {
  const [activeTheme, setTheme] = useLocalStorage(StorageKey.Theme)

  const {
    activeRole,
    roles,
    messages,
    onClearRole,
    onRoleAuth,
    onRoleLogout,
    onUseEducatorRole,
    onUseParentRole,
  } = useRoleAuth()
  const { service, onServiceAuth, onServiceLogout } = useSessionAuth()
  const sessionTimer = useRef<Nullable<number>>(null)
  const [resetSessionTimeoutCounter, setResetSessionTimeoutCounter] = useState(0)
  const [resetSessionTimerRequired, setResetSessionTimerRequired] = useState(false)

  const isEducator = Auth.isEducator(activeRole)

  const onServiceLogoutAttempt = () => {
    if (service && confirm(`Are you sure you want to sign out of ${service.name}?`)) {
      onServiceLogout()
    }
  }

  const onThemeToggle = () => setTheme(activeTheme === 'light' ? 'dark' : 'light')

  // If service changes, redirect to login if null or dashboard otherwise
  useEffect(() => {
    service ? navigate('/service') : navigate('/')
  }, [service])

  const setSessionTimeout = () => {
    // Timeout after 45s
    return setTimeout(() => {
      onRoleLogout()
      navigate('/service')
    }, 45000)
  }

  // If role list changes, navigate to role selection. If there's only one role,
  // the activeRole will get set so watch for that change instead
  useEffect(() => {
    if (roles.length > 0) {
      sessionTimer.current = setSessionTimeout()

      return () => {
        if (sessionTimer.current) clearTimeout(sessionTimer.current)
      }
    }
  }, [roles])

  const onResetSessionTimeout = () => {
    if (sessionTimer.current) clearTimeout(sessionTimer.current)

    sessionTimer.current = setSessionTimeout()

    setResetSessionTimeoutCounter(previousCounter => previousCounter + 1)

    setResetSessionTimerRequired(false)
  }

  return (
    <StardustProvider activeTheme={activeTheme || 'light'} themes={themes}>
      <Router>
        {service && (
          <>
            <ServiceDashboard
              path="/service"
              service={service}
              onServiceLogout={onServiceLogoutAttempt}
              onSessionEnd={onRoleLogout}
              onThemeToggle={onThemeToggle}
            />
            <ParentEducatorLogin path="/login" service={service} onAuth={onRoleAuth} />
            <VisitorLog path="/visitors" />
            <VisitorForm path="/visitors/new" />
          </>
        )}

        {service && roles.length > 0 && (
          <RoleSelection
            path="/role-selection"
            roles={roles}
            onClearRole={onClearRole}
            onUseEducatorRole={onUseEducatorRole}
            onUseParentRole={onUseParentRole}
            service={service}
          />
        )}

        {service && activeRole && (
          <>
            <ChildAttendance
              path="/attendance"
              roles={roles}
              activeRole={activeRole}
              service={service}
              resetSessionTimerRequired={resetSessionTimerRequired}
              onResetSessionTimeout={onResetSessionTimeout}
            />
            <CasualSessions path="/casual/:childId" service={service} />
          </>
        )}

        {service && activeRole && messages.length > 0 && (
          <Messages
            path="/messages"
            messages={messages}
            service={service}
            roles={roles}
            setResetSessionTimerRequired={setResetSessionTimerRequired}
          />
        )}

        {service && activeRole && (
          <EmployeeShift path="/shift" activeRole={activeRole} roles={roles} service={service} />
        )}

        <ServiceLogin default service={service} onServiceAuth={onServiceAuth} />
      </Router>

      {roles.length > 0 && (
        <SessionTimer
          key={`non_educator_timer_${resetSessionTimeoutCounter}`}
          color="primary"
          duration={45}
          isVisible={!isEducator}
        />
      )}
      {roles.length > 0 && (
        <SessionTimer
          key={`educator_timer_${resetSessionTimeoutCounter}`}
          color="secondary"
          duration={45}
          isVisible={isEducator}
        />
      )}
    </StardustProvider>
  )
}

export default React.memo(App)
