import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as UseFetchProvider } from 'use-http'
import * as Analytics from '~/helpers/analytics'
import apiOptions from '~/api'
import config from '~/config'

import App from '~/pages/App'

Analytics.initialise()

const Root = () => (
  <UseFetchProvider url={config.HUB_API} options={apiOptions}>
    <App />
  </UseFetchProvider>
)

ReactDOM.render(<Root />, document.getElementById('root'))

// de-register the serviceWorker left over from hub1
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister()
  })
}
