import React from 'react'

import { BackgroundImage, Box, Card, Loading, Stack, Tiles } from '@myxplor/stardust'

import { Content } from '~/layouts/FixedHeaderLayout'

import homeLoginImage from '~/assets/images/home_login.jpg'
import FeedbackIcon from '~/icons/Feedback'

import { ShiftState } from './interfaces'

import Clock from './Clock'
import ShiftAction from './ShiftAction'
import ShiftProgressBar from './ShiftProgressBar'

interface Props {
  shift: Nullable<Xplor.Shift>
  shiftState: Nullable<ShiftState>
  isUpdating: boolean
  onShiftAction(action: string): void
  onShowComment(): void
}

const ShiftDashboard = ({ shift, shiftState, isUpdating, onShiftAction, onShowComment }: Props) => {
  return (
    <>
      <Box height="200px" position="absolute" top="120px" width={1}>
        <BackgroundImage image={homeLoginImage} height="200px" width={1} borderRadius="0 0 16px 16px" />
      </Box>

      <Content withFooter withHeader>
        <Box marginTop="130px">
          {shift && shiftState ? (
            <Stack align="center" space="large" invertSpace>
              <Card>
                <Box padding="xxlarge" paddingTop="large">
                  <Box padding="medium" position="absolute" right={0} top={0} onClick={onShowComment}>
                    <FeedbackIcon />
                  </Box>
                  <Stack align="center" space="large">
                    <Clock />
                    <ShiftProgressBar shift={shift} />
                  </Stack>
                </Box>
              </Card>

              <Box paddingX="xxlarge">
                <Tiles columns={2} space="xxlarge">
                  <ShiftAction
                    shift={shift}
                    action={shiftState.action1}
                    isDisabled={isUpdating}
                    onShiftAction={onShiftAction}
                  />
                  <ShiftAction
                    shift={shift}
                    action={shiftState.action2}
                    isDisabled={isUpdating}
                    onShiftAction={onShiftAction}
                  />
                </Tiles>
              </Box>
            </Stack>
          ) : (
            <Loading size="large" />
          )}
        </Box>
      </Content>
    </>
  )
}

export default React.memo(ShiftDashboard)
