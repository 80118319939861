import React, { useState, useEffect } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import useFetch from 'use-http'

import {
  Alert,
  Anchor,
  Box,
  Button,
  Form,
  Image,
  Inline,
  NumpadField,
  PageHeading,
  Stack,
  Text,
  TextField,
} from '@myxplor/stardust'

import * as Analytics from '~/helpers/analytics'

import HeroImageLayout from '~/layouts/HeroImageLayout'

import LoadingModal from '~/components/LoadingModal'

import BackIcon from '~/icons/Back'

import { AuthMode } from './common'
import ForgotLoginModal from './ForgotLoginModal'
import NoAccountModal from './NoAccountModal'

import xplorRocketImage from '~/assets/images/xplorange.svg'

interface Props extends RouteComponentProps {
  service: Xplor.Service
  onAuth(tokens: Xplor.Token[], service: Xplor.Service): void
}

const ParentEducatorLogin = ({ service, onAuth }: Props) => {
  const [authMode, setAuthMode] = useState<AuthMode>('pin')
  const [errors, setErrors] = useState<Nullable<string>>(null)
  const [identifier, setIdentifier] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [password, setPassword] = useState('')

  const { request } = useFetch()

  const onIdentifierChange = (value: string) => {
    setErrors(null)
    setIdentifier(value)
  }

  const onPasswordChange = (value: string) => {
    setErrors(null)
    setPassword(value)
  }

  const onSubmit = async () => {
    setErrors(null)
    setLoading(true)

    const params = {
      identifier: identifier.trim(),
      password,
      service_id: service.id,
      provider_id: service.provider_id,
    }

    const endpoints = [
      '/api/auth/educator/hub',
      '/api/auth/educator/stargate',
      '/api/auth/parent/hub',
      '/api/auth/parent/stargate',
    ]
    const responses = await Promise.all(endpoints.map(endpoint => request.post(endpoint, params)))

    if (responses.every(resp => !resp || !!resp.errors)) {
      setErrors(responses[0].errors)
      setLoading(false)
      Analytics.auth(false)
    } else {
      const tokens = responses.reduce((acc: Xplor.Token[], resp, index) => {
        if (!resp) return acc
        const endpoint = endpoints[index]
        const userType = endpoint.indexOf('educator') >= 0 ? 'Educator' : 'Parent'
        return resp.errors ? acc : [...acc, { ...resp.data, user_type: userType }]
      }, [])
      onAuth(tokens, service)

      Analytics.auth(true)
      Analytics.setUser(identifier)
      Analytics.setAuthMode(authMode)

      responses.forEach((token: Xplor.Token, index: number) => {
        if (endpoints[index].indexOf('hub') >= 0) {
          Analytics.setAuthMethod('Hub')
        } else {
          Analytics.setAuthMethod('Xplor ID')
        }
      })
    }
  }

  useEffect(() => {
    if (identifier && authMode === 'pin' && password.length === 4) {
      onSubmit()
    }
  }, [password])

  const onSubmitPasswordReset = async (email: string, reset_type: 'email' | 'phone') => {
    setErrors(null)
    setLoading(true)

    let errorMessage
    try {
      await request.post('/api/auth/password-reset', { identifier: email, identifier_type: reset_type })
    } catch (error) {
      console.error(error)
      errorMessage = 'Unable to communicate with server. Please contact support'
    }
    setLoading(false)
    return errorMessage
  }

  const onSwitchAuthMode = (authMode: AuthMode) => {
    setAuthMode(authMode)
    setErrors(null)
    setIdentifier('')
    setPassword('')
  }

  return (
    <HeroImageLayout image="https://dsetjzxhxo9kr.cloudfront.net/home/splash.png">
      <Stack align="left">
        <PageHeading level={4} linkColor="primary" overline="Dashboard" onBack={() => navigate('/service')}>
          Xplor Login
        </PageHeading>

        <Stack align="center" space="xlarge">
          <Box paddingBottom="small" paddingTop="xxlarge" width={72}>
            <Image src={xplorRocketImage} />
          </Box>
          <Form onSubmit={onSubmit}>
            <Stack align="center" space="xlarge">
              <Stack space="small">
                <Stack>
                  {authMode === 'password' && (
                    <Anchor color="primary" onClick={() => onSwitchAuthMode('pin')}>
                      <Inline space="xxsmall" wrap={false}>
                        <BackIcon />
                        Use PIN
                      </Inline>
                    </Anchor>
                  )}
                </Stack>

                <Box width={1}>
                  <Box
                    opacity={authMode === 'pin' ? 1 : 0}
                    pointerEvents={authMode === 'pin' ? 'all' : 'none'}
                    transform={authMode === 'pin' ? 'translateX(0)' : 'translateX(-100%)'}
                    transition="opacity 0.2s, transform 0.2s"
                    width={1}>
                    <Stack space="small">
                      <NumpadField
                        label="Phone number"
                        maxLength={50}
                        name="identifier"
                        value={identifier}
                        onChange={onIdentifierChange}
                      />
                      <NumpadField
                        clearOnFocus
                        label="PIN"
                        maxLength={4}
                        name="password"
                        type="password"
                        value={password}
                        onChange={onPasswordChange}
                      />
                    </Stack>
                  </Box>

                  <Box
                    opacity={authMode === 'password' ? 1 : 0}
                    pointerEvents={authMode === 'password' ? 'all' : 'none'}
                    position="absolute"
                    top={0}
                    transform={authMode === 'password' ? 'translateX(0)' : 'translateX(100%)'}
                    transition="opacity 0.2s, transform 0.2s"
                    width={1}>
                    <Stack space="small">
                      <TextField
                        label="Email"
                        name="identifier"
                        type="email"
                        value={identifier}
                        onChange={onIdentifierChange}
                      />
                      <TextField
                        label="Password"
                        name="password"
                        type="password"
                        value={password}
                        onChange={onPasswordChange}
                      />
                    </Stack>
                  </Box>
                </Box>

                {errors && <Alert tone="negative">{errors}</Alert>}
              </Stack>
              <Button disabled={!identifier || !password || isLoading} fill type="submit">
                Login
              </Button>
            </Stack>
          </Form>
          <Inline align="center" alignY="center" space="xxxsmall">
            <ForgotLoginModal
              authMode={authMode}
              isLoading={isLoading}
              onSubmit={onSubmitPasswordReset}
              onSwitchAuthMode={onSwitchAuthMode}>
              {authMode === 'password' ? <Anchor>Forgot password?</Anchor> : <Anchor>Forgot pin?</Anchor>}
            </ForgotLoginModal>
            <Text color="primary">&bull;</Text>
            <NoAccountModal>
              <Anchor>No account?</Anchor>
            </NoAccountModal>
          </Inline>
        </Stack>
      </Stack>

      <LoadingModal isOpen={isLoading} />
    </HeroImageLayout>
  )
}

export default React.memo(ParentEducatorLogin)
