import config from '~/config'

import { sortObjectArray, uniqueBy } from '~/helpers/utils'

export function findStargateToken(auths: Xplor.Token[]) {
  return auths.find(x => x.token_type === 'hodor')
}

export async function generateRoles(tokens: Xplor.Token[], userContexts: Xplor.UserContext[]) {
  const stargateRoles = userContexts.map(userContext => {
    return {
      ...userContext,
      user_id: userContext.strategy_id,
      user_type: userContext.strategy_type,
      token: 'replaceme',
      token_type: 'stargate',
    } as Xplor.Role
  })

  const hodorRoles = await Promise.all(
    tokens.map(async token => {
      const data = await getHodorUserDetails(token)
      return {
        ...token,
        first_name: data.first_name,
        last_name: data.last_name,
        identifier: data.email,
        user_id: data.id,
      } as Xplor.Role
    })
  )

  const roles = [...hodorRoles, ...stargateRoles]
  const uniqueRoles = uniqueBy(roles, r => r.user_id)
  return sortObjectArray(uniqueRoles, ['user_type', 'first_name'])
}

export async function getStargateUserContexts(request: any, service: Xplor.Service) {
  const response = await request.get(`/api/stargate/user_contexts/${service.id}`)

  if (response.errors) {
    return []
  } else {
    return response.data
  }
}

export async function getTokenForRole(request: any, role: Xplor.Role) {
  if (role.token_type === 'stargate') {
    return await switchUserContext(request, role)
  } else {
    return role.token
  }
}

export function isEducator(role: Nullable<Xplor.Role>) {
  return !!role && role.user_type === 'Educator'
}

export async function switchUserContext(request: any, role: Xplor.Role) {
  const params = { user_id: role.user_id, user_type: role.user_type }
  const response = await request.post('/api/stargate/auth_context', params)
  return response.data.token
}

// The use-http library does not have a way to dynamically change headers per
// request, and the effort required to fix that is non-trivial. In the longer term
// we should try to contribute to the library, but in the short term we're
// using fetch directly to do what we need
async function getHodorUserDetails(token: Xplor.Token) {
  const namespace = token.user_type?.toLowerCase()
  const response = await fetch(`${config.HUB_API}/api/${namespace}`, {
    method: 'get',
    mode: 'cors',
    headers: { Authorization: `Bearer ${token.token}`, 'Content-Type': 'application/json' },
  })

  if (response.ok) {
    const body = await response.json()
    return body.data
  }

  return { first_name: '', last_name: '', email: '' }
}
