import React, { useEffect, useState } from 'react'

import { Box, Color } from '@myxplor/stardust'

interface Props {
  color: Color
  duration: number
  isVisible: boolean
}

const SessionTimer = ({ color, duration, isVisible }: Props) => {
  const [scale, setScale] = useState('scaleX(1)')

  useEffect(() => {
    // Somehow setTimeout is required for the transition animation to work
    setTimeout(() => setScale('scaleX(0)'), 0)
  }, [])

  return (
    <Box
      backgroundColor={color}
      borderBottomRightRadius="small"
      borderTopRightRadius="small"
      height="8px"
      left={0}
      opacity={isVisible ? 1 : 0}
      position="fixed"
      top={0}
      transform={scale}
      transformOrigin="top left"
      transition={`transform ${duration}s linear, background-color 0.3s linear ${duration - 10}s`}
      width={1}
      zIndex={11}
    />
  )
}

export default React.memo(SessionTimer)
