import React from 'react'

import { Anchor, Box, Card, Column, Columns, Heading, Inline } from '@myxplor/stardust'

import LoginIcon from '~/icons/Login'

interface Props {
  casualSession: Xplor.SuggestedBooking
  onSignIn(cb: Xplor.SuggestedBooking): void
}

const SessionCard = ({ casualSession, onSignIn }: Props) => {
  const startTime = casualSession.start_time
  const finishTime = casualSession.finish_time
  const roomName = casualSession.room_name

  const handleClick = () => {
    onSignIn(casualSession)
  }

  return (
    <Card onClick={handleClick}>
      <Box padding="large">
        <Columns alignY="center">
          <Column>
            <Heading level={6} weight="regular">{`${startTime} - ${finishTime}`}</Heading>
          </Column>
          <Column align="right">
            <Anchor>
              <Inline alignY="center" space="xxsmall" wrap={false}>
                <LoginIcon />
                {roomName}
              </Inline>
            </Anchor>
          </Column>
        </Columns>
      </Box>
    </Card>
  )
}

export default React.memo(SessionCard)
