import { sha256 } from 'js-sha256'
import ReactGA from 'react-ga4'

import * as utils from '~/helpers/utils'
import config from '~/config'

export async function initialise() {
  ReactGA.initialize([
    {
      trackingId: config.GOOGLE_ANALYTICS_PROPERTY || '',
      gaOptions: {
        debug_mode: config.DEBUG,
        sampleRate: 100,
        siteSpeedSampleRate: 5,
      },
      gtagOptions: {
        debug_mode: config.DEBUG,
        send_page_view: true,
      },
    },
  ])
}

export async function setUser(identifier: string) {
  ReactGA.set({ userId: sha256(identifier) })
}

export async function setAuthMode(mode: string) {
  ReactGA.event({ category: 'Auth Mode', action: utils.startCase(mode) })
}

export async function setAuthMethod(method: string) {
  ReactGA.event({ category: 'Auth Method', action: utils.startCase(method) })
}

export async function auth(success: boolean) {
  ReactGA.event({ category: 'Auth', action: utils.startCase(success.toString()) })
}

export async function setRole(role: string) {
  ReactGA.event({ category: 'Set Role', action: utils.startCase(role) })
}

export async function setRoleCount(count: number) {
  ReactGA.event({ category: 'Roles', action: 'Count', value: count })
}

export async function setService(serviceID: number) {
  ReactGA.set({ userId: `${serviceID}` })
}

export async function visitorLogIn() {
  ReactGA.event({ category: 'Service', action: 'Visitor Log In' })
}

export async function visitorLogOut() {
  ReactGA.event({ category: 'Service', action: 'Visitor Log Out' })
}

export async function educatorShiftCheckIn() {
  ReactGA.event({ category: 'Educator', action: 'Shift Start' })
}

export async function educatorShiftCheckOut() {
  ReactGA.event({ category: 'Educator', action: 'Shift End' })
}

export async function educatorShiftBreakStart() {
  ReactGA.event({ category: 'Educator', action: 'Break Start' })
}

export async function educatorShiftBreakEnd() {
  ReactGA.event({ category: 'Educator', action: 'Break End' })
}

export async function educatorShiftComment(submitted: boolean) {
  ReactGA.event({
    category: 'Educator',
    action: 'Shift Comment',
    label: `${submitted ? 'Submitted' : 'Cancelled'}`,
  })
}

export async function educatorNoShift() {
  ReactGA.event({ category: 'Educator', action: 'No Shift' })
}

export async function parentBookingSignIn() {
  ReactGA.event({ category: 'Parent', action: 'Check In' })
}

export async function parentBookingSignOut() {
  ReactGA.event({ category: 'Parent', action: 'Check Out' })
}

export async function parentCasualBooking() {
  ReactGA.event({ category: 'Parent', action: 'Casual Booking' })
}

export async function parentNoCasualBookings() {
  ReactGA.event({ category: 'Parent', action: 'No Casual Bookings' })
}

export async function parentNoChildren() {
  ReactGA.event({ category: 'Parent', action: 'No Children' })
}

export async function parentReadMessage() {
  ReactGA.event({ category: 'Parent', action: 'Read Message' })
}
