import React from 'react'

const Back = () => (
  <svg width="8" height="17" viewBox="0 0 8 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.6327 0.498001C7.98128 0.498001 8.10374 0.754781 7.90418 1.06704L3.55793 7.92683C3.35834 8.27609 3.35834 8.71991 3.55793 9.06918L7.90418 15.929C8.10374 16.2434 7.98128 16.498 7.6327 16.498H5.49456C5.10348 16.4745 4.74059 16.2672 4.49871 15.929L0.149226 9.07131C-0.0497418 8.72185 -0.0497418 8.27842 0.149226 7.92896L4.49612 1.06918C4.73799 0.730984 5.10089 0.523622 5.49197 0.500135L7.6327 0.498001Z"
      fill="currentColor"
    />
  </svg>
)

export default Back
