import React from 'react'

import { Anchor, Avatar, Box, Card, Column, Columns, Heading, Inline, Stack, Text } from '@myxplor/stardust'

import { formatLocalTime } from '~/helpers/dates'

import LogoutIcon from '~/icons/Logout'

const formatTime = (dt: Nullable<string>) => (dt ? formatLocalTime(dt) : null)
const getInitials = (visitor: Xplor.Visitor) => visitor.first_name[0] + visitor.last_name[0]
const getName = (visitor: Xplor.Visitor) => `${visitor.first_name} ${visitor.last_name}`

interface Props {
  visitor: Xplor.Visitor
  onSignOut(visitor: Xplor.Visitor): void
}

const VisitorCard = ({ visitor, onSignOut }: Props) => {
  const handleSignOut = () => onSignOut(visitor)
  const isCurrentlySignedIn = visitor.status

  return (
    <Box paddingLeft="xlarge" width={1}>
      <Card>
        <Box padding="small" paddingRight="large" width={1}>
          <Columns alignY="center" space="small">
            <Column>
              <Box marginLeft="-64px">
                <Avatar active={!!isCurrentlySignedIn} label={getInitials(visitor)} size="medium" />
              </Box>
            </Column>

            <Column width="fill">
              <Stack>
                <Inline alignY="bottom" space="xxxsmall">
                  <Heading level={6}>{getName(visitor)}</Heading>
                </Inline>
                <Text color="mediumEmphasis" prefab="subtitleAlt">
                  {`Signed in ${formatTime(visitor.signed_in)}`}
                </Text>
              </Stack>
            </Column>

            <Column>
              {isCurrentlySignedIn ? (
                <Anchor decorate="none" onClick={handleSignOut}>
                  <Columns alignY="center" space="xxsmall">
                    <Column>
                      <LogoutIcon />
                    </Column>
                    <Column width="fill">
                      <Text prefab="button" weight="medium">
                        Sign out
                      </Text>
                    </Column>
                  </Columns>
                </Anchor>
              ) : (
                <Text prefab="bodyAlt">{`Signed out ${formatTime(visitor.signed_out)}`}</Text>
              )}
            </Column>
          </Columns>
        </Box>
      </Card>
    </Box>
  )
}

export default React.memo(VisitorCard)
